.review-page{
    //@include tiempos();
    .hero-section{
        padding:0px;
    }
    .text{
        .section_title{
            color: #0D6747;
            font-weight:normal;
        }
    }
    .ticket-info{
        font-weight: normal;
        margin-top:30px;

        .header{
            @include tiempos(medium);
            color: #006747;
            margin-bottom: 15px;
            font-size: px2rem(20);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            div:nth-child(1){
                width:30%;
                float:left;
                
            }
            h3 {
                margin-bottom: 0;
            }
            .edit-account-info{
                @include bentonsans(medium);
                font-size: px2rem(12);
                color:#009B77;
    
                a {
                    text-decoration: none;
                }
                p{
                    font-size:px2rem(12);
                    color:#009B77;
                    
                }
                @include breakpoint(small) {
                    font-size:px2rem(14);
                }
            }
        }    
    }
    .policy-buttons{
        a {
            width: 30%;
            padding: 30px;
            font-size: px2rem(14);
            font-weight: normal;
            text-decoration: none;
            color: $lt_green;

        }
    }
    .total {
        margin-top:20px;
        @include bentonsans(medium);
        font-size: px2rem(18);
        text-align: right;
    }
    #application-status {
        margin-bottom:10px;;
    }
}
.printLogo{
    display:none;
    width:156px;
    margin:0px auto !important;
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
}

.ticket-info{
    font-weight: normal;
    margin-top:50px;

    .header{
        @include tiempos(medium);
        color: #006747;
        margin-bottom: 0px;
        font-size: px2rem(20);
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        div:nth-child(1){
            width:30%;
            float:left;
            
        }
        h3 {
            margin-bottom: 0;
        }
        .edit-account-info{
            @include bentonsans(medium);
            font-size: px2rem(12);
            color:#009B77;

            a {
                text-decoration: none;
            }
            p{
                font-size:px2rem(12);
                color:#009B77;
                
            }
            @include breakpoint(small) {
                font-size:px2rem(14);
            }
        }
    }    
}

@media print{

  //  * { overflow: visible !important;} 
    html, body {
        border: 1px solid white;
        margin-top:0px;
        position: absolute;
        top:0;
        width:100%;
        * {
            font-size: px2rem(12);
        }
    }
    .page-actions, .hero, #anwa-header, #bottom, .edit-account-info, .backToTop, .policy-buttons{
        display:none!important;
        visibility:none!important;
    }
    .printLogo{
       display:block;
    }
    body .print-header #eventLogo {
        width:250px;
    }

    .account-info {
        margin-top:0px;
        .header {
            margin-top:0px;
        }
    }

    .ticket-info {
        margin-top:0px;
        .header {
            margin-top:10px;
        }

        .ticket-application {
            &:nth-child(1) {
                margin-top:0px;
            }
        }
    }
    //section.section-title-wrapper h1, section.page-title h1
    section {
      &.section-title-wrapper {
        h1, h2 {padding:0px; font-size: px2rem(20); line-height: normal;}
      }  
      &.page-title {
        margin:0px;
        h1, h2 {padding:0px; font-size:px2rem(20); line-height: normal;}
      }
      &.page-grid {
        &.cols-1 {
            .page-grid-item {
                margin-top:0px;
            }
        }
      }
    }

    #print-area{

        //margin-top:10px;
        .review-page {
            transform: scale(0.95); // this shrinks the page
            transform-origin: 0 0;
            line-height: 1.1em;
            height:auto;
            .ticket-info{
                //margin-top:400px;
                margin-top:0px;
                .ticket-title{
                    margin-top:20px;
                }
                
                .ticket-application {
                    &:nth-child(1) {
                        margin-top:0px;
                    }
                    h4.ticket-title {
                        margin-top:10px;
                    }
                }

                .header{
                    h3{
                         margin-bottom:0px!important;
                     }
                 }

            }
        }

        .ticket-application {
            .ticket-title {
                margin-top:0px;
                line-height: normal;
            }
            table {
                margin-top:10px;
            }
        }
    }

    .page-content.backToTopWrapper {display:none;}
    
    .ticket-application table td, .ticket-application table th{
        
        &.highlight {
            print-color-adjust: exact;
            background-color: #00AC69;
        }
    }

    .calendarContainer {
        display:none;
    }
    .jsx-parser {
        p {line-height: 1.2em;}
    }
}
    .ticket-application table td, .ticket-application table tr th,  .ticket-application table th{
        &.highlight {
            background-color: #00AC69!important;
        }
    }
    .account-info table td:nth-child(2), .ticket-application .ticket-title, .card-header div{
        color:#00AC69;
    }
