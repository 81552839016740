.article-image {
    position: relative;
    padding-bottom: 56.2%;
    height: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.65);
        color: #ffffff;
        padding: 15px 40px 15px 30px;
        font-size: 14px;
        text-align: right;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 14px;
            right: 20px;
            height: 17px;
            width: 1px;
            background-color: #b1b3b3;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(135deg);
        }
    }
    .icon {
        position: absolute;
        background: rgba(0, 0, 0, 0.65);
        bottom: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        @include breakpoint(medium){
            width: 30px;
            height: 30px;
        }
        & {border-radius: 50%;}
        & {display: block;}
        & {cursor: pointer;}
        &:after {
            content: 'i';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            font-family: Tiempos, Times New Roman, serif;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            @include breakpoint(medium){
                line-height: 30px;
                font-size: 14px;
            }
        }
    }
}

.article-image-container {
    &.video {
        cursor: pointer;
    }
    & {position: relative;}
    @include breakpoint(medium){
        height: 64vh;
        max-height: 820px;
        .article-image {
            height: 100%;
            padding-bottom: 0
        }
    }
}

.anwa-site {
	.article-image-container {
	    @include breakpoint(medium){
	        height: 50vh;
	    }
	}
}
