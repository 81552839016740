.address-confirmation {
    margin-top:20px;
    .form-content {
        margin-top: 0;

        .checkbox-option { 
            margin-bottom: 20px;
            label {
                float:left;
                &::before{
                    top:0px;
                }
            }
        }
    }
}