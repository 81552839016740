@mixin breakpoint($break, $type : min) {
  @if $type == min {
    @if $break == xsmall {        // 375px
      @media only screen and (min-width: calc((375/16)*1em)) { @content; }
    } @else if $break == small {  // 768px 42em
      @media only screen and (min-width: calc((768/16)*1em)) { @content; }
    } @else if $break == medium { // 992px 62em
      @media only screen and (min-width: calc((992/16)*1em)) { @content; }
    } @else if $break == large {  // 1200px
      @media only screen and (min-width: calc((1200/16)*1em)) { @content; }
    } @else if $break == xlarge { // 1440px
      @media only screen and (min-width: calc((1440/16)*1em)) { @content; }
    }
  } @else if $type == max {
    @if $break == xsmall {        // 319px
      @media only screen and (max-width: calc((374/16)*1em)) { @content; }
    } @else if $break == small {  // 767px
      @media only screen and (max-width: calc((767/16)*1em)) { @content; }
    } @else if $break == medium { // 991px
      @media only screen and (max-width: calc((991/16)*1em)) { @content; }
    } @else if $break == large {  // 1199px
      @media only screen and (max-width: calc((1199/16)*1em)) { @content; }
    } @else if $break == xlarge { // 1439px
      @media only screen and (max-width: calc((1439/16)*1em)) { @content; }
    }
  }
}

@mixin orientation($type) {
  @if $type==landscape {
    @media only screen and (orientation: landscape) {
      @content
    }
  } @else if $type == portrait {
    @media only screen and (orientation: portrait) {
      @content
    }
  }
}