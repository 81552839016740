.form-container {
    &.application-form {
        .form-content {
            .four-columns {
                justify-content: flex-start;
                margin-bottom: 20px;

                .application-item {
                    @include breakpoint(small) {
                        width: 22%;
                    }
                    & {margin-right: 3%;}
                    .event-place{
                        margin-top:10px;
                        font-size:0.9rem;
                    }
                }

                .fixed-label {
                    width: 100%;
                    margin-bottom: 10px;
                    white-space: nowrap;
                }
            }
            .single-column {
                justify-content: flex-start;
                margin-bottom: 20px;

                .application-item {
                    @include breakpoint(small) {
                        width: 80%;
                    }
                    & {margin-right: 3%;}
                    .event-place{
                        margin-top:10px;
                        font-size:0.9rem;
                    }
                }

                .fixed-label {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}