@function opposite-position($directions){
  $opposite-positions: ();
  $direction-map: (
    'top':    'bottom',
    'right':  'left',
    'bottom': 'top',
    'left':   'right',
    'center': 'center',
    'ltr':    'rtl',
    'rtl':    'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);
    
    @if map-has-key($direction-map, $direction) { 
      $opposite-positions: append($opposite-positions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }
 
  @return $opposite-positions;
}

@mixin border-arrow($width, $height, $color, $direction) {
    @if not index(top right bottom left, $direction) {
        @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }

    width: 0;
    height: 0;
    content: '';

    $size: $width;
    $perp-size: $height;
    @if $direction == top or $direction == bottom {
        $size: $height;
        $perp-size: $width;
    }

    border-#{opposite-position($direction)}: $size solid $color;

    $perpendicular-borders: calc(#{$perp_size}/2) solid transparent;

    @if $direction == top or $direction == bottom {
        border-left:   $perpendicular-borders;
        border-right:  $perpendicular-borders;
    } @else if $direction == right or $direction == left {
        border-bottom: $perpendicular-borders;
        border-top:    $perpendicular-borders;
    }
}

@mixin chevron-arrow-direction($direction) {
    @if not index(top right bottom left, $direction) {
      @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }
  
    @if $direction == right {
      transform: translate3d(-25%,0,0) rotate(45deg);
    } @else if $direction == left {
      transform: translate3d(25%,0,0) rotate(-135deg);
    } @else if $direction == top {
      transform: translate3d(0,25%,0) rotate(-45deg);
    } @else if $direction == bottom {
      transform: translate3d(0,-25%,0) rotate(135deg);
    }
  }
  
  @mixin chevron-arrow($width, $height, $thickness, $color, $direction: null) {
    width: $width;
    height: $height;
  
    border-top: $thickness solid $color;
    border-right: $thickness solid $color;
  
    @if($direction != null) {
      @include chevron-arrow-direction($direction);
    }
  }