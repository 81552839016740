
@font-face {
font-family: 'Benton Sans';
src: url('/assets/fonts/bentonsans/BentonSans-Book.eot');
src: url('/assets/fonts/bentonsans/BentonSans-Book.eot?#iefix') format("embedded-opentype"),
     url('/assets/fonts/bentonsans/BentonSans-Book.woff2') format("woff2"),
     url('/assets/fonts/bentonsans/BentonSans-Book.woff') format("woff"),
     url('/assets/fonts/bentonsans/BentonSans-Book.otf') format("opentype");
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Benton Sans';
src: url('/assets/fonts/bentonsans/BentonSans-Medium.eot');
src: url('/assets/fonts/bentonsans/BentonSans-Medium.eot?#iefix') format("embedded-opentype"),
     url('/assets/fonts/bentonsans/BentonSans-Medium.woff2') format("woff2"),
     url('/assets/fonts/bentonsans/BentonSans-Medium.woff') format("woff"),
     url('/assets/fonts/bentonsans/BentonSans-Medium.otf') format("opentype");
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'Benton Sans';
src: url('/assets/fonts/bentonsans/BentonSans-BookItalic.eot');
src: url('/assets/fonts/bentonsans/BentonSans-BookItalic.eot?#iefix') format("embedded-opentype"),
     url('/assets/fonts/bentonsans/BentonSans-BookItalic.woff2') format("woff2"),
     url('/assets/fonts/bentonsans/BentonSans-BookItalic.woff') format("woff"),
     url('/assets/fonts/bentonsans/BentonSans-BookItalic.otf') format("opentype");
font-weight: normal;
font-style: italic;
}

@font-face {
	font-family: 'Tiempos';
	src: url('/assets/fonts/tiempos/TiemposHeadline-Regular.woff2') format('woff2'),
		url('/assets/fonts/tiempos/TiemposHeadline-Regular.ttf') format('truetype'),
		url('/assets/fonts/tiempos/TiemposHeadline-Regular.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Tiempos Headline';
	src: url('/assets/fonts/tiempos/TiemposHeadline-Regular.woff2') format('woff2'),
		url('/assets/fonts/tiempos/TiemposHeadline-Regular.ttf') format('truetype'),
		url('/assets/fonts/tiempos/TiemposHeadline-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Tiempos Headline Light';
	src: url('/assets/fonts/tiempos/TiemposHeadline-Light.woff2') format('woff2'),
		url('/assets/fonts/tiempos/TiemposHeadline-Light.ttf') format('truetype'),
		url('/assets/fonts/tiempos/TiemposHeadline-Light.otf') format('opentype');
}

// Font definition mixins
@mixin bentonsans($weight: null) {
  $fallback_fonts: 'Arial, Helvetica, sans-serif';
  $font: "'Benton Sans', BentonSans";

  @if ($weight == medium or $weight == bold) {
    font-weight: 500;
  } @else if($weight == italic) {
    font-style: italic;
  } @else {
    font-weight: normal;
    font-style: normal;
  }
  font-family: #{$font}, #{$fallback_fonts};
}

@mixin tiempos($weight: null) {
	$fallback_fonts: 'Times New Roman, serif';
	$font: 'Tiempos Headline';
	$font2: 'Tiempos';
	$font3: 'Tiempos Headline Light';

	font-family: $font, #{$fallback_fonts};
	font-weight: 500;

	@if ($weight == medium) {
		font-family: $font2, $font, #{$fallback_fonts};
		font-weight: normal;
	} @else if($weight == light) {
		font-family: $font3, #{$fallback_fonts};
		font-weight: normal;
	} @else if($weight == bold) {
		font-family: $font, #{$fallback_fonts};
		font-weight: 500;
	} @else {
		font-family: $font, $font2, #{$fallback_fonts};
		font-weight: normal;
	}
}

@mixin display($size: null) {
  @include tiempos();
  font-size: px2rem(52);
  line-height: 78px;

  @if ($size == lg) {
    font-size: px2rem(64);
    line-height: 80px;
  } @else if ($size == md) {
    font-size: px2rem(52);
    line-height: 78px;
  } @else if (size == sm) {
    font-size: px2rem(42);
    line-height: 60px;
  }
}

@mixin heading($size: null) {
  @include tiempos();
  font-size: px2rem(16);
  line-height: 24px;

  @if ($size == 5xl) {
    font-size: px2rem(46);
    line-height: 64px;
  } @else if ($size == 4xl) {  
    font-size: px2rem(42);
    line-height: 52px;
  } @else if ($size == 3xl) {  
    font-size: px2rem(38);
    line-height: 48px;
  } @else if ($size == 2xl) {  
    font-size: px2rem(34);
    line-height: 44px;
  } @else if ($size == xl) {  
    font-size: px2rem(32);
    line-height: 42;
  } @else if ($size == lg) {  
    font-size: px2rem(30);
    line-height: 42;
  } @else if ($size == md) {  
    font-size: px2rem(28);
    line-height: 34;
  } @else if ($size == sm) {  
    font-size: px2rem(24);
    line-height: 34;
  } @else if ($size == xs) {  
    font-size: px2rem(22);
    line-height: 30px;
  } @else if ($size == 2xs) {  
    font-size: px2rem(18);
    line-height: 26px;
  } @else if ($size == 3xs) {  
    font-size: px2rem(16);
    line-height: 24px;
  } @else if ($size == 4xs) {  
    font-size: px2rem(14);
    line-height: 20px;
  } @else if ($size == 5xs) {  
    font-size: px2rem(11);
    line-height: 15px;
  }
}

@mixin body($size: md, $weight: null, $style: null) {
  @include bentonsans(book);
  font-size: px2rem(16);
  line-height: 24px;

  @if ($weight == medium) {
    @include bentonsans(medium)
  } @else if ($weight == italic or $style == italic) {
    @include bentonsans(italic)
  }

  @if (style == caps) {
    text-transform: uppercase;
  } @else if (style == underline) {
    text-decoration: underline;
  }

  @if ($size == 5xl) {
    font-size: px2rem(66);
    line-height:normal;
  } @else if ($size == 4xl) {
    font-size: px2rem(48);
    line-height:68px;
  } @else if ($size == 3xl) {
    font-size: px2rem(30);
    line-height:30px;
  } @else if ($size == 2xl) {
    font-size: px2rem(24);
    line-height:42px;
  } @else if ($size == xl) {
    font-size: px2rem(20);
    line-height:28px;
  } @else if ($size == lg) {
    font-size: px2rem(18);
    line-height:26px;
  } @else if ($size == md) {
    font-size: px2rem(16);
    line-height:24px;
  } @else if ($size == sm) {
    font-size: px2rem(14);
    line-height:20px;
  } @else if ($size == xs) {
    font-size: px2rem(13);
    line-height:19px;
    @if ($weight == medium) {
      line-height: 13px;
    }
  } @else if ($size == 2xs) {
    font-size: px2rem(12);
    line-height:18px;
  }



}