@mixin clearfix() {
    & { *zoom:1; }
  
    &:before,
    &:after {
        content:"";
        display:table;
    }
    &:after {
        clear:both;
    }
  }

@mixin wh($w, $h: null) {
    width:$w;
    @if($h == null) { height: $w; }
    @else { height: $h; }
}

@function cp($px, $max: 1440px) {
    @return calc(#{$px}/#{$max} * 100%);
}

  // mixin for dropdown menu dropshadow
@mixin dd-box-shadow {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}

@mixin input-placeholder {
  // @include with-each-prefix(css-placeholder, $input-placeholder-support-threshold) {
    // @if $current-prefix == -webkit {
      &::-webkit-input-placeholder {
        @content;
      }
    // }
    // @else if $current-prefix == -moz {
      // for Firefox 19 and below
      // @if support-legacy-browser("firefox", "4", "19", $threshold: $input-placeholder-support-threshold) {
        &:-moz-placeholder {
          @content;
        }
      // }
      // for Firefox 20 and above
      &::-moz-placeholder {
        @content;
      }
    // }
    // @else if $current-prefix == -ms {
      &:-ms-input-placeholder {
        @content;
      }
    // }
  // }

  // This is not standardized yet so no official selector is generated.
}

@mixin filterscale( $path ) {
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src="#{$path}", sizingMethod="scale");
}

@mixin resizable_icon( $path ) {
  background-image: url('#{$path}');
  .ie8 & {
    background-image: none;
    @include filterscale('#{$path}');
  }
}

// $size must be defined in px
@mixin centered_icon_size( $size, $base : 12px ) {
  // base font size defaults to 12px
  $em_size: #{calc(#{$size}/#{$base}) + 'em'};
  $margin_size: #{calc((#{$size}/#{$base})/-2) + 'em'};
  & { width: $em_size; height: $em_size; background-size: $em_size $em_size; }
  // margin-top: $margin_size; margin-left: $margin_size;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
          transition: $transition;
}

@mixin nav_transition( $property: all ) {
  $transition : '';
  @each $prop in $property {
    $trans : $prop 0.5s ease-out;
    @if $prop != nth($property,1) {
      $transition : #{$transition}, #{$trans};
    } @else {
      $transition : $trans;
    }
  }
  @include transition($transition);
}

@mixin no_transition {
  // @include single-transition(none);
}

@mixin chromescrollfix {
  -webkit-transform: translateZ(0);
}

@mixin dd-box-shadow {
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
}

// Z-INDEX
// https://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
$elements: page, hero, headerlink, dropdownmenu, panel, alertbar, lbeverywhere, notifications, mainmenu, header, lightbox;
$title_bar: selector, share, close;
$track_map_elements: map, gradient, weather, markers, dialog, search_link, player_nav, holeinfo, overlay;
$lbeverywhere: playoff_info, search;

@function z($list, $element) {
    $z-index: index($list, $element);
  
    @if $z-index {
      @return $z-index;
    }
  
    @warn 'There is no item "#{$element}" in this list; choose one of: #{$list}';
    @return null;
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback), 
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;
  
  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }
  
  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;
    
    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }
  
  @if $fallback {
    font-size: $fallback;
  }
  
  font-size: $responsive;
}

@mixin multitruncate($font-size, $line-height, $lines-to-show) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $line-height*$lines-to-show*1em; /* Fallback for non-webkit */
  font-size: $font-size*1em;
  line-height: $line-height*1em;
  -webkit-line-clamp: $lines-to-show;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  @-moz-document url-prefix() {
    overflow: hidden;
    position: relative;
  }
}

@mixin score_line($pos : outer, $circle : no) {
  box-sizing: content-box; content: ' '; position: absolute; top: 50%; left: 50%;
  height: 0; @include score_line_size($pos);
  border: 1px solid $dark_green;
  @if($circle != no) {
    border-radius: 50%;
  }
}
@mixin score_line_size($pos : outer) {
  $size: -1;
  $margin: -1;
  $margin-left: -1;
  $margin-top: -1;
  @if($pos == outer) {
    $size : 32px;
    $margin-top : -18px;
    $margin-left: -17px;
  } @else if($pos == inner) {
    $size : 26px;
    $margin-top : -15px;
    $margin-left: -14px;
  } @else if($pos == outer_large) {
    $size : 31px;
    $margin-top : -17px;
    $margin-left: -16px;
  } @else if($pos == inner_large) {
    $size : 25px;
    $margin-top : -14px;
    $margin-left: -13px;
  } @else if($pos == outer_medium) {
    $size : 26px;
    $margin : -15px;
    $margin-left: -14px;
  } @else if($pos == inner_medium) {
    $size : 20px;
    $margin : -12px;
    $margin-left: -11px;
  } @else if($pos == outer_small) {
    $size : 22px;
    $margin-top : -13px;
    $margin-left: -12px;
  } @else if($pos == inner_small) {
    $size : 16px;
    $margin-top : -10px;
    $margin-left: -9px;
  }@else if($pos == outer_xsmall) {
    $size : 19px;
    $margin-top : -11px;
    $margin-left: -10px;
  } @else if($pos == inner_xsmall) {
    $size : 13px;
    $margin-top : -8px;
    $margin-left: -7px;
  }

  @if($margin-left == -1) {
    $margin-left: $margin;
  }
  @if($margin-top == -1) {
    $margin-top: $margin;
  }
  width: $size; padding-bottom: $size; margin: $margin-top 0 0 $margin-left;
}

@mixin get-sprite($map, $sprite, $height: true, $width: true) {

  //http://compass-style.org/reference/compass/helpers/sprites/#sprite-file
  $sprite-image: sprite-file($map, $sprite);

  // http://compass-style.org/reference/compass/helpers/sprites/#sprite-url
  $sprite-map: sprite-url($map);

  // http://compass-style.org/reference/compass/helpers/sprites/#sprite-position
  $sprite-position: sprite-position($map, $sprite);

  // Returns background
  background: $sprite-map $sprite-position no-repeat;

  // http://compass-style.org/reference/compass/helpers/image-dimensions/
  // Checks to see if the user wants height returned
  @if $height == true {
    // Gets the height of the sprite-image
    $sprite-height: image-height($sprite-image);
    // Returns the height
    height: $sprite-height; }

  // http://compass-style.org/reference/compass/helpers/image-dimensions/
  // Checks to see if the user wants height returned
  @if $width == true {
    // Gets the width of the sprite-image
    $sprite-width: image-width($sprite-image);
    // Returns the width
    width: $sprite-width; }
}