.containerPhoto{
    width: 100%;
    padding-bottom: 1.8em;
    & { @include clearfix; }
    & { position: relative; display: inline-block; }
    a { cursor: pointer;}
    img{ display: inline-block; width: 100%; }
    &.half{ float: left; width: 20%;}
    &.left{ float: left; width: 20%;}
    &.right{ float: right; width: 20%;}
    &.center,  &.center_resize, &.full{ display: block; margin: 0px auto; text-align: center;}
    &.full{ 
        img{max-width: 992px; }
    }
    &.founders {
        img{max-width: 992px; }

        @include breakpoint(small) {

        }
    }
    &.center_resize{ max-width: 375px;
        img{ width: 100%; height: auto; }
        @include breakpoint(medium){ max-width: 810px;
            img{ width: auto;}
        }
    }
    .caption-credit {
        margin-top: -5px;
        .caption {
            margin-top: 0px;
            height: auto;
            white-space: initial;
            line-height: initial;
            font-size: 0.6875rem;
            font-family: 'Benton Sans', BentonSans, Arial, Helvetica, sans-serif;            
            color: #53565a;
            display: inline;            
        }
        .credit {
            margin: 3px 0 0;
            padding-left: 3px;
            color: #888b8d;
            font-style: italic;
            font-family: 'Benton Sans', BentonSans, Arial, Helvetica, sans-serif;
            white-space: nowrap;
            font-size: 0.6875rem;
            height: auto;
            line-height: 12px;
            display: inline;
        }
    }    
}

/** About the Masters page - special layout to display founders photos
 *  there is an extra <div class="founders half | full"> around .containerPhoto div tag
 *  block for mobile view, side by side above tablet
 */
.founders {
    &.full {
        @include breakpoint(small) {
            display: none;
        }
    } // full
    
    &.half {
        display: none;

        @include breakpoint(small) {
            width: 20%;
            float: left;
            display: inline-block;
        }
        
        .containerPhoto {
            &.half {
                @include breakpoint(small) {
                    width: 100%;
                }
            }
        } // containerPhoto
    } // half
} // founders

 
/** Used on Awads & Trophes page to tile the images
*/
.flex-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .containerPhoto {
    }
}

/** Used on Tournament Foundation page
*/
.tourn-foundation {
    .containerPhoto {
        &.center_resize {
            padding-bottom: 0;
            text-align: left;
            margin: 0 4%;
            max-width: 135px;
        }
    }
}