.button{
    text-align:center;
    // margin-bottom: 10%;
    outline:none;
    cursor:pointer;
    width: 230px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: px2rem(14) !important;
    // margin:0 20px;
    background-color: $white;
    color:$dark_green !important;
    border: $dark_green 1px solid;
    text-decoration: none !important;
    @include bentonsans(book);
    font-weight: normal !important;
    
    @include breakpoint(small, max) {
        &.full {
            width: 100%;
        }
    }
    &:disabled, &[disabled], &.disabled{
        border: 1px solid $disabled_color;
        background-color: $white;
        color: $disabled_color !important;
        cursor:default;
    }
    &:active, &:focus{
        outline:none;
    }
    &.white {
        background-color: transparent;
        border-color: $white;
        color: $white !important;
        opacity: .3;
            
        &.active {
            opacity: 1;
            cursor: pointer;
        }
        &:hover {
            outline: 1px solid $white;
        }
    }
    &.green {
      background-color: $dark_green;
      color:$white !important;
      border: $dark_green 1px solid;
    }
}

.log-in-container, .create-account-container {
    .button {
        width: 100% !important;
    }
}
