.back-link {
    position: relative;
    @include clearfix;

    .backlink {
        position: relative;
        height: 20px;

        a {
            @include bentonsans(medium);
            font-size: px2rem(12px);
            color: $lt_green;
            text-decoration: none;
            padding-left: 15px;
            position: relative;

            &:before {
                position: absolute;
                top: 2px;
                left: 0px;
                @include border-arrow(6px, 10px, $lt_green, left);
                cursor: pointer;
            }
        }

        &.left {
            float: left;
            margin-left: 1.69492%;
        }
    }

   @include breakpoint(medium) {
        .backlink {
            margin-top: 15px;

            a {
                font-size: px2rem(14px);

                &:before {
                    top: 3px;
                }
            }

            &.left {
                margin-left: 0;
            }
        }
    }
}
