//2019 ANWA colors

$white: #ffffff;
$black: #000000;
$off_white: #ebebeb;
$live_color: #ed1c24;
$body_text: #53565A;

// Primary
$dark_green: #00AC69;
$lt_green: #009b77;
// Secondary
$off_white: #f2f2f1;
$gray_b: #b1b3b3;
$gray_c: #C8C9C7;
$gray_d: #d9d9d6;
$gray_d2: #d8d8d8;
$gray_2: #25282a;
$gray_5: #53565a;
$gray_6: #6B6F73;
$gray_8: #888b8d;

$orange: #EAAA00;
$nav_border: #E8E4DC;

// Accent
$bright_red: #DF2F3B; //#ef3340;
$dark_red: #ba0c2f;
$yellow: #FAE345;
$video: #fef7b2;

// extra found colors
$nav_text: #53565A; // event date
$lt_off_white: #efefef; // lb cutline hover
$gray: #333333; //article byline
$gray_e: #e8e8e8; // menu select option border
$gray_f: #f7f7f7; // submenu border color
$gray_3: #3C3C3B; // lb skeumorphic border & fonts color
$green_4: #45987e; // lb responsive view border color
$green_0: #009b77; // lb skeumorphic footer concurrent rounds selected color
$green_8: #81ad82; // lb skeumorphic footer denotes text color
$schedule-border: #e1e1e1;

$green_link_color: #339933;
$selected_color: $lt_green;
$darker_selected_color: #dfc713;
$disabled_color: #C8C9C7;

// default lighter score colors on dark background
$new_under_color: $dark_red;
$new_over_color: $dark_green;

// darker color
$under_color: #C10230;
$over_color: #00AC69;

//disabled color
$gray-disable: #9c9c9c;

//page content background;
$page-content-bg: #ECF5F4;
$schedule-bg: #f5f5f5;

//Slider colors
$slider-inactive: #C0D8D5;
$arrow-inactive: #E8E4DC;

// scorecard - no videos for ANWA to avoid compile error, adding masters videos colors here
// override no-video color to white
$no-video: $white;
$video-standard: $white;
$video-highlight: #fef7b2; // video highlight/produced video

// r2 playoff table border
$r2playoff_border: #EAE7E0;


//step-indicator
$step-circle-color: #00AC69;