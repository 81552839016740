// show dropdown under small breakpoint (768px)
// show tabs above small breakpoint (768px)

.select-menu-dropdown2tabs{
    width: 100%;
    position: relative;

    button {
        background: transparent;
    }

    .title{
        background: none;
        display: inline-block;
        position: relative;
        width: auto;
        height: 30px;
        border: 0px;
        color: $dark-green;
        text-decoration: none;
        text-align: left;
        text-transform: uppercase;
        padding: 0 30px 0 0;
        opacity: 1;
        cursor: pointer;
        @include tiempos;
        font-size: #{px2rem(18px)};

        &.border {
            border: 1px solid $dark-green;
		}

		.menu-arrow {
			font-size: 18px;
			line-height: 10px;
        }

        @include breakpoint(small) { // 768px
            display: none;
        } // breakpoint(small)
    } // title

    &-dropdown{
        background: $white;
        position: absolute;
        z-index: 10;
        display: none;
        min-width: 175px;
        padding: 0;
        @include bentonsans(semibold);
        font-size: 15px;
        max-height: 400px;
        @include dd-box-shadow;

        &.show {
            display: block;
        }

        .option{
            display: block;
            border-top: 1px solid $gray_e;
            color: $dark-green;
            margin: 0;
            padding: 10px 15px;;
						text-align: left;
						background-color: unset;

            &.selected{
                color: $lt_green;

                &:before {
                    content: '';
                    width: 3px;
                    height: 100%;
                    background-color: $lt_green;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                }

            }

            &:hover {
                color: $lt_green;
            }

						&.disabled {
							color: $disabled_color;

							  &:hover {
									cursor: default;
								}
						}
        }

        button {
            flex: 0 0 auto;
            position: relative;
            white-space: nowrap;
            @include bentonsans(semibold);
            font-size: #{px2rem(15px)};

            text-decoration: none;
            border: 0;
            cursor: pointer;
            width: 100%;

            &:first-child {
                border-top: none;
            }
        }

        .section {
            text-align: center;
            border-bottom: 1px solid $dark-green;
        }

        @include breakpoint(small) { // 768px
            display: block;
            box-shadow: none;
            position: relative;
            width: 100%;
            border-bottom: 2px solid $lt_green;

            .option {
                @include bentonsans(medium);
								border-top: 1px solid $gray_d;
								border-right: 1px solid $gray_d;
								border-bottom: none;
								border-left: 1px solid transparent;
								background: $white;
		            color: $dark_green;
                display: inline-block;
                font-size: #{px2rem(11px)};
                line-height: #{px2rem(35px)};
								padding: 0 18px;
		            margin: 0;
                width: auto;

                &:first-child {
										border-top: 1px solid $gray_d;
                    border-left: 1px solid $gray_d;
                }

                &.selected {
										background: $lt_green;
										color: $white;
										border-top: 1px solid $lt_green;
										border-left: 1px solid $lt_green;
										border-right: 1px solid $lt_green;

                    &:before {
                        background: none;
                    }
                }

								&:hover {
										background: $lt_green;
										color: $white;
										border-top: 1px solid $lt_green;
										border-left: 1px solid $lt_green;
										border-right: 1px solid $lt_green;
		                cursor: pointer;
		            }

								&.disabled {
		                color: $disabled_color;

		                &:hover {
												border-top: 1px solid $gray_d;
												border-right: 1px solid $gray_d;
												border-left: 1px solid transparent;
												background: $white;
		                    color: $disabled_color;
		                    cursor: default;
		                }
		            }
            }
        } // breakpoint(small)

				@include breakpoint(medium) { // 992px
					.option {
							font-size: #{px2rem(13px)};
							line-height: #{px2rem(45px)};
							padding: 0 25px;
					}
				}
    } // -dropdown

    .navigation_down_arrow { position: relative;
        &:after { position: absolute; top: 15px; right: 5px; @include border-arrow(10px, 5px, $dark_green, bottom); }
        &.gray_8 {
          &:after { @include border-arrow(8px, 4px, $gray_6, bottom); }
        }

        .active &, &.active, .open &, &.open {
          &:after { transform: rotate(180deg); transition: 0.15s transform ease-in-out; }
        }
    }

}
