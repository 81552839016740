.account-info{
    //width:50%;
    font-weight: normal;
    // margin:50px;
    display:flex;
    flex-direction: column;
    
    .header{
        // @include tiempos(medium);
        // color: #006747;
        margin-bottom: 0px;
        // font-size: px2rem(20);
        // font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:nth-child(1){
            width:30%;
            float:left;
            
        }
        .edit-account-info{
          display: flex;
            @include bentonsans(medium);
            font-size: px2rem(12);
            color:#009B77;

            a {
                text-decoration: none;
            }
            .separator {
              margin: 0 10px;
              width: 1px;
              border-left: 1px solid #6B6F73;
            }

            @include breakpoint(small) {
                font-size:px2rem(14);
            }
        }
    }
    table {
        @include bentonsans(medium);
        border-collapse: collapse;
        td, th {
            border: none;
            padding: 0.5rem;
            text-align: left;
        }
        td:nth-child(1){
            font-size:px2rem(12);
            padding-left:0px;
            padding-right:50px;
            width:20%;
            color:#6B6F73;
            font-weight:100;
        }
        td:nth-child(2){
            @include tiempos(medium);
            color: #006747;
        }
    }
}