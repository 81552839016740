.junior-pass {
    .form-content {
        margin-top: 0;

        .checkbox-option, .radio-option { 
            margin-bottom: 20px;
            label {
                float:left;
                &::before{
                    top:0px;
                }
            }
        }
        .checkbox-group-container {
            margin-left:40px;
        }
        .radio-option {
            label {
                float:none;
            }
        }
    }
}