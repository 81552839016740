/*
     1) Set a min-font-size of 16px when viewport width < 600px
     2) Set a max-font-size of 32px when viewport width > 1200px and
     3) linearly increase the font-size from 16->32px
     between a viewport width of 600px-> 1200px
*/
section.hero-section {
    width:100%;
    &.content-page {
        img {max-height: 325px;}
    }
    &.quarter-height {
        img {
            display: block;
            width: 100%;
            padding: 0;
            height: 100px;
            object-fit: cover;
            max-height:200px;
        }

        @include breakpoint(medium) {
            img {height: auto}
        }
        @include breakpoint(large) {
            img {
                // height: auto;
                // max-height: 200px;
            }
        }
    }
    &.multi-margin {
        padding: 0 0 $sm-content-margin;
    }
    &.half-height {
        @include breakpoint(large) {
            img {
                height: auto;
                max-height: 500px;
                width: 100%;
            }
        }
    }

    @include breakpoint(medium) {
        padding: 0 $lg-content-margin $sm-content-margin;
    }

    @include breakpoint(large) {
        &.multi-margin {
            padding: 0 $lg-content-margin $sm-content-margin;
        }
    }

    .background {
        &-third-height {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 250px;
            width: 100%;

            // @include lbbreakpoint(lbMobileLandscape) {
            //     height: 375px;
            // }

            @include breakpoint(medium) {
                height: 500px;
            }
        }
    }

}