.buttonLink {
	max-width: 992px;
	width: 92%;
	display: block;
	margin: 20px auto;
	text-align: center;

	a {
		display: inline-block;
		text-decoration: none;
		text-transform: capitalize;
		&:hover {
			color: $lt_green;
			cursor: pointer;
		}
	}

	button {
		border: 1px solid $dark_green;
		background-color: $white;
		color: $dark_green;
		font-size: px2rem(16);
		height: 100%;
		min-width: 275px;
		width: 100%;
		line-height: 40px;
		padding: 0px 20px;
		cursor: pointer;
		&:hover {
			color: $lt_green;
		}
		@include breakpoint(medium) {
			height: 40px;
		}
	}
}