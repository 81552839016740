nav.old[role=navigation] {
    li { list-style: none; color: $dark_green; font-size: px2rem(16); text-align: left;
      &.selected { border-left: 3px solid $selected_color;
        a { margin-left: $sm-content-margin; }
      }
      a { color: $dark_green; text-decoration: none; display: block; margin-left:$sm-content-margin; margin-right: $sm-content-margin; border-bottom: 1px solid $nav_border; line-height: 60px; height: 60px;
        &:hover, &.selected { color: $selected_color; }
      }
      &:first-child {
        a { border-top: 1px solid $gray_f; }
      }

      &#more {
        &:after { display: none; }
        & > div { display: none; }
      }
    }
    span.live { padding: 0.2em 0.5em; margin-left: 0.5em; }
}

//section.nav default - 375px (xsmall) breakpoint
section.nav.old{
    position: fixed; top: $mobile-sb-height; height: $mobile_nav_height; width: 100%; background-color: $white; z-index: z($elements, header);

    &.active { position: relative; }

    .sponsors { float: right; margin-top: 14px; display: block; text-align: right;
        img { display: inline-block; }

        .ibm {
            img { width: 34px; height: 12px; }
        }
        .att {
            img { width: 39px; height: auto; }
        }
        .mb {
            img { width: 20px; height: 20px; }
        }
		.boa {
			img { width: 37px; height: 20px; }
		}
		.rolex {
			img { width: 39px; height: 21px; }
		}
        .ibm, .att, .mb, .boa, .rolex{
            display: none;
            &.show{display: block;}
        }
	}
	
    nav.submenu {
        ul { margin-top: 0; margin-bottom: 40px; }
        li { @include bentonsans; font-size: px2rem(16);
          &:first-child a { border-top: 0; }
        }
		.schedule_sub {
			display: none;
		}
    }

	#eventLogo {
		position: absolute;
		background: url(/assets/images/nav/anwa_logo.png) no-repeat; background-size: 116px 50px;
		width: 116px;
		height: 50px;
		background-position: center;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 10;

		a {
			display: block;
			width: 116px;
			height: 50px;
			text-indent: -9999px;
		}
	}

	#eventDate {
		color: $nav_text;
		font-size: px2rem(11);
		font-weight: 500;
		white-space: nowrap;
		position: absolute;
		top: 65px;
		left: 50%;
		-webkit-transform: translateX(-50%);
    	transform: translateX(-50%);

		span {
			display: table-cell;
   			vertical-align: middle;
		}
	}

    //section.nav 992px (medium) breakpoint
    @include breakpoint(medium){
		top: $medium-sb-height;
        height: $medium-nav-height;

		#eventLogo {
			background-size: 142px 61px;
			width: 142px;
			height: 61px;

			a {
				width: 142px;
				height: 61px;
			}
		}

		#eventDate {
			font-size: px2rem(12);
			position: relative;
			top: inherit;
			left: inherit;
			-webkit-transform: 0;
	    	transform: none;

			padding-right: 30px;
			border-right: solid 1px $nav_border;
			margin-top: 0;
			margin-right: 30px;
			line-height: 37px;
			span {
				display: table-cell;
	   			vertical-align: middle;
			}
		}

        nav.mainmenu {
            &.active { max-height: 0!important; min-height: $medium-nav-height!important; /* with the mobile menu shown, if the window is changed, class .active still exists. set the height for tablet view to show the tablet menu */ }
            ul { margin: 0; border: 0; height: $medium-nav-height;
				//display: flex;
				padding-top: 15px;
			}

            li { display: inline-block; margin: 0; @include bentonsans; font-weight: 500; white-space: nowrap; color: $dark_green; margin-right: 40px;
              &:first-child a { border-top: 0; }
              a { background: none;
                &:hover { text-decoration: none;}
              }
              &#more {
				margin-right: 30px;
				cursor: pointer;
                &:after { display: block; }
                & > div { display: inline-block; }
              }
			  &#home {
				  display: none;
			  }
			}
			
            & > ul > li { font-size: px2rem(12);
              &.selected { border-left: 0; }
              & > a, .moreLink { padding: 0; margin: 0; border: 0;
                &:hover { color: $lt_green; }
                &.selected { position: relative; color: $lt_green;
                  &:after { content: ''; width: 100%; position: absolute; bottom: 0; left: 0; height: 2px; background-color: $lt_green; }
                }
              }
			  .moreLink { padding-right: 19px; line-height: 60px; }
			}
			
            span.live { padding: 0.2em 0.5em; margin-left: 0.3em; line-height: 16px; margin-top: -2px; display: inline-block; }

			&.noLB {
				li {
					&#leaderboard {
						display: none;
					}
					&#course {
						margin-left: auto;
					}
					&#schedule {
					  margin-left: inherit;
					}
				}

				.submenu {
					.schedule_sub {
						display: none;
					}
				}
			}

			&.stubBoth {
				li {
					&#leaderboard {
						display: none;
					}
					&#tee {
						display: none;
					}
					&#course {
						// margin-left: inherit;
					}
					&#schedule {
						display: block;
					  margin-left: auto;
					}
				}

				.submenu {
					.schedule_sub {
						display: none;
					}
				}
			}

			&.fullMenu {
				li {
					// &#tee {
					// 	margin-left: auto;
					// }
					&#schedule {
						display: none;
					}
				}
			}

			&.onAir {
				li {
					&#live {
						display: block;
					}
				}
			}

			&.offAir {
				li {
					&#live {
						display: none;
					}
				}
			}
        }

        nav.submenu { display: none; position: absolute; top: 58px; right: 0;
            width: 245px; @include chromescrollfix; background-color:$white; border: 1px solid $nav_border;
            &.active { display: block; }
            ul { display: block; float: none; height: auto; margin-bottom: 0; margin-left: 0; padding-top: inherit; }
            li { display: block; margin-right: 0; background-color:$white;
              a { display: block; margin: 0 15px; }
              &.selected a { margin-left: 15px - 3px; }
            }

			.schedule_sub {
				display: block;
			}
        }

        .sponsors { z-index: z($elements, header); margin-top: 0; width: 75px;
            .ibm {
              img { width: 50px; height: 18px; }
            }
            .att {
              img { width: 54px; height: auto; }
            }
            .mb {
              img { width: 28px; height: 28px; }
            }
			.boa {
				img { width: 52px; height: 28px; }
			}
			.rolex {
				img { width: 50px; height: 27px;}
			}
        }
    }

	//section.nav 1200px (large) breakpoint
	@include breakpoint(large){
        position: fixed; top: $sb_height; height: $nav_height; padding: 0; border-bottom: 0;

		#eventLogo {
			position: absolute;
			background: url(/assets/images/nav/anwa_logo.png) no-repeat; background-size: 220px 94px;
			width: 220px;
			height: 94px;
			background-position: center;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 10;

			a {
				display: block;
				width: 220px;
				height: 94px;
				text-indent: -9999px;
			}
		}

		#eventDate {
			font-size: px2rem(14);
			position: relative;
			top: inherit;
			left: inherit;
			-webkit-transform: 0;
	    	transform: none;

			padding-right: 30px;
			border-right: solid 1px $nav_border;
			margin-top: 45px;
			margin-right: 30px;
			line-height: 37px;
			span {
				display: table-cell;
	   			vertical-align: middle;
			}
		}

        .navigation_down_arrow {
            &:after { position: absolute; top: 29px; right: 0px; @include border-arrow(10px, 5px, $dark_green, bottom); }
        }

        nav.mainmenu {
            &.active { max-height: 0!important; min-height: $nav_height!important; /* with the mobile menu shown, if the window is changed, class .active still exists. set the height for tablet view to show the tablet menu */ }
            ul { margin: 0; border: 0; height: $nav_height;
				//display: flex;
				padding-top: 40px;
			}
            li { display: inline-block; margin: 0; @include bentonsans; font-weight: 500; white-space: nowrap; color: $dark_green; margin-right: 40px;
              &:first-child a { border-top: 0; }
              a { background: none;
                &:hover { text-decoration: none;}
              }
              &#more {
				margin-right: 40px;
				cursor: pointer;
                &:after { display: block; }
                & > div { display: inline-block; }
              }
            }
            & > ul > li { font-size: px2rem(16);
              &.selected { border-left: 0; }
              & > a, .moreLink { padding: 0; margin: 0; border: 0;
                &:hover { color: $lt_green; }
                &.selected { position: relative; color: $lt_green;
                  &:after { content: ''; width: 100%; position: absolute; bottom: 0; left: 0; height: 2px; background-color: $lt_green; }
                }
              }
			  .moreLink { padding-right: 19px; line-height: 60px; }
            }
            span.live { padding: 0.2em 0.5em; margin-left: 0.3em; line-height: 16px; margin-top: -2px; display: inline-block; }
        }

        nav.submenu { display: none; position: absolute; top: 58px; right: 0;
            width: 245px; @include chromescrollfix; background-color:$white; border: 1px solid $nav_border;
            &.active { display: block; }
            ul { display: block; float: none; height: auto; margin-bottom: 0; margin-left: 0; padding-top: inherit; }
            li { display: block; margin-right: 0; background-color:$white;
              a { display: block; margin: 0 15px; }
              &.selected a { margin-left: 15px - 3px; }
            }
        }

        .sponsors { z-index: z($elements, header); margin-top: 35px; width: 75px; }
    }

}

.alert {
	section.nav.old{
		top: $mobile-sb-height + $global-alert-height;

		@include breakpoint(medium){
			top: $medium-sb-height + $global-alert-height;
		}

		@include breakpoint(large){
			top: $sb_height + $global-alert-height;
		}
	}
}

// Scrolling (smaller) navigation styles
.smaller {
	// default - 375px (xsmall) breakpoint
	section.nav.old {
		top: $mobile-sb-height;
		height: $mobile-nav-height-scroll;

		#eventLogo {
			background: url(/assets/images/nav/anwa_logo_small.png) no-repeat; background-size: 33px 29px;
			width: 33px;
			height: 29px;

			a {
				width: 33px;
				height: 29px;
			}
		}

		#eventDate {
			margin-top: 0;
			top: 40px;
		}

	}

	@include breakpoint(medium) {
		section.nav.old {
			top: $medium-sb-height-scroll;
			height: $nav-height-scroll;

			#eventLogo {
				background: url(/assets/images/nav/anwa_logo_small.png) no-repeat; background-size: 47px 42px;
				width: 47px;
				height: 42px;

				a {
					width: 47px;
					height: 42px;
				}
			}

			#eventDate {
				margin-top: 0;
				top: 0;
			}

			nav.mainmenu {
	            &.active { min-height: $nav-height-scroll!important; }
				ul { height: $nav-height-scroll;
					padding-top: 0;
					align-items: center;
				}
			}

			.sponsors {
				margin-top: 0;
			}
		}
	}

	@include breakpoint(large) {
		section.nav.old {
			top: $mobile-sb-height; height: $mobile_nav_height;

			#eventLogo {
				background: url(/assets/images/nav/anwa_logo_small.png) no-repeat; background-size: 51px 46px;
				width: 51px;
				height: 46px;

				a {
					width: 51px;
					height: 46px;
				}
			}

			#eventDate {
				margin-top: 0;
			}

			nav.old.mainmenu {
	            &.active { min-height: $nav-height-scroll!important; }
				ul { height: $nav-height-scroll;
					padding-top: 0;
					align-items: center;
				}
			}

			.sponsors {
				margin-top: 0;
			}
		}
	}

	&.alert {
		section.nav.old {
			top: $mobile-sb-height + $global-alert-height;
			height: $mobile-nav-height-scroll;

			@include breakpoint(medium){
				top: $medium-sb-height-scroll + $global-alert-height;
				height: $nav-height-scroll;
			}

			@include breakpoint(large){
				top: $sb_height + $global-alert-height;
				height: $nav-height-scroll;
			}
		}
	}
}


//Mobile Navigation styles
body.js-nav {
	overflow: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;

	// @include breakpoint(small) {
	// 	position: relative;
	// 	height: auto;
	// }
} // prevent body content from being scrollable
.js-nav {
	.alert {
		section.nav {
			top: 15px;
		}
	}
}

/***************************************/

nav.mainmenu {
	display:none;
	li { list-style: none; color: $dark_green; font-size: px2rem(16); text-align: left; white-space: nowrap; @include bentonsans(medium);
		&.selected { border-left: 3px solid $selected_color;
		  a { margin-left: $sm-content-margin; }
		}
		a { color: $dark_green; text-decoration: none; display: block; margin-left:$sm-content-margin; margin-right: $sm-content-margin; border-bottom: 1px solid $nav_border; line-height: 60px; height: 60px;
		  &:hover, &.selected { color: $selected_color; }
		}
		&:first-child {
		  a { border-top: 1px solid $gray_f; }
		}
  
		&#more {
			cursor:pointer;
		  //&:after { display: none; }
		  & > div { display: none; }
		}
	  }
	span.live { padding: 0.2em 0.5em; margin-left: 0.5em; }

	&.noLB {
		li {
			&#leaderboard {
				display: none;
			}
			&#course {
				margin-left: auto;
			}
			&#schedule {
			  margin-left: inherit;
			}
		}

		.submenu {
			.schedule_sub {
				display: none;
			}
		}
	}

	&.stubBoth {
		li {
			&#leaderboard {
				display: none;
			}
			&#tee {
				display: none;
			}
			&#course {
				// margin-left: auto;
			}
			&#schedule {
				display: block;
			  margin-left: auto;
			}
		}

		.submenu {
			.schedule_sub {
				display: none;
			}
		}
	}

	&.fullMenu {
		li {
			&#leaderboard {
				margin-left:0px;
			}
			// &#tee {
			// 	margin-left: auto;
			// }
			&#schedule {
				display: none;
			}
		}
		> ul {
			> li {
				&:nth-child(5) {
					margin-left:auto;
					margin-right:0;
				}
			}
		}
	}

	&.onAir {
		li {
			&#live {
				display: block;
			}
		}
	}

	&.offAir {
		li {
			&#live {
				display: none;
			}
		}
	}

	.navigation_down_arrow { position: relative;
        &:after {content:""; position: absolute; /*top: calc(50% + 6px)*/; right: -18px; display:none;
            @include border-arrow(10px, 5px, $dark_green, bottom);
            transition: 0.15s transform ease-in-out;
        }
        &.gray_8 {
            &:after { @include border-arrow(10px, 5px, $gray_6, bottom); }
        }

        .active &, &.active, .open &, &.open {
            &:after { transform: rotate(180deg); transition: 0.15s transform ease-in-out; }
        }
	}

	@include breakpoint(medium) {
		width:100%; 
		display:block;
		order:1;
		margin-top:30px;
		padding-right:30px;
		> ul {
			//display:flex;
			//flex-direction: row;
			//padding:0px 30px;
			> li {
				//@include bentonsans(medium);
				font-size:px2rem(12);
				margin:0px 20px;
				a {
					border-bottom:none;
				}
				&.selected {
					border-left:none;
					border-bottom: 2px solid $selected_color;
				}
			}
		}
		
		li#home {
			display:none;
		}

		li#more {
			padding-right:0px;
			display:flex;
			align-items: center;
			.moreLink {
				display:block;
			}
		}

		// &.noLB {
		// 	li {
		// 		&#leaderboard {
		// 			display: none;
		// 		}
		// 		&#course {
		// 			margin-left: auto;
		// 		}
		// 		&#schedule {
		// 		  margin-left: inherit;
		// 		}
		// 	}

		// 	.submenu {
		// 		.schedule_sub {
		// 			display: none;
		// 		}
		// 	}
		// }

		// &.stubBoth {
		// 	li {
		// 		&#leaderboard {
		// 			display: none;
		// 		}
		// 		&#tee {
		// 			display: none;
		// 		}
		// 		&#course {
		// 			margin-left: inherit;
		// 		}
		// 		&#schedule {
		// 			display: block;
		// 		  margin-left: auto;
		// 		}
		// 	}

		// 	.submenu {
		// 		.schedule_sub {
		// 			display: none;
		// 		}
		// 	}
		// }

		// &.fullMenu {
		// 	li {
		// 		&#leaderboard {
		// 			margin-left:0px;
		// 		}
		// 		&#tee {
		// 			margin-left: auto;
		// 		}
		// 		&#schedule {
		// 			display: none;
		// 		}
		// 	}
		// 	> ul {
		// 		> li {
		// 			&:nth-child(5) {
		// 				margin-left:auto;
		// 				margin-right:0;
		// 			}
		// 		}
		// 	}
		// }

		// &.onAir {
		// 	li {
		// 		&#live {
		// 			display: block;
		// 			padding-left:0px;
		// 		}
		// 		&#leaderboard {
		// 			padding-left: 20px;
		// 		}
		// 	}
		// }

		// &.offAir {
		// 	li {
		// 		&#live {
		// 			display: none;
		// 		}
		// 	}
		// }

		.navigation_down_arrow {
			&:after {
				display:flex;
				align-items: center;
			}
		}

		header.smaller & {
			margin-top:0px;
		}
	}

	@include breakpoint(large) {
		> ul > li {
			font-size: px2rem(16);
		}
	}

	// @include breakpoint(large) {
	// 	.navigation_down_arrow {
	// 		&:after {
	// 		  top: calc(50% + 4px);
	// 		}
	// 	}
	// }
}

nav.submenu {
	ul { margin-top: 0; margin-bottom: 40px; }
	li { @include bentonsans(medium); font-size: px2rem(16);
	  &:first-child a { border-top: 0; }
	}
	.schedule_sub {
		display: none;
	}

	@include breakpoint(medium) {
		display: none; position: absolute; top: 58px; right: -20px;
		width: 245px; @include chromescrollfix; background-color:$white; border: 1px solid $nav_border;

		&.active { display: block; }

		ul { display: block; float: none; height: auto; margin-bottom: 0; margin-left: 0; padding-top: inherit; }
		li { display: block; margin-right: 0; background-color:$white;
			font-size:px2rem(12);
			a { display: block; margin: 0 15px; }
			&.selected a { margin-left: 15px - 3px; }
		}

		.schedule_sub {
			display: block;
		}
	}

	@include breakpoint(large) {
		li {
			font-size: px2rem(16);
		}
	}
}

section.subheader {
	//position: fixed; 
	//position:relative;
	//top: $mobile-sb-height; 
	//height: $mobile_nav_height; 
	width: 100%; 
	background-color: $white; 
	z-index: z($elements, header);
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	padding:$sm-content-margin;
	flex-wrap: wrap;
	height:$mobile-header-height;
	max-width:1440px;
	margin:0px auto;

	.sponsors { 
		display:flex;
		//align-items: flex-end;
		align-items: center;
		justify-content: flex-end;
		width:25%;
		margin-top: -5px;
        img { display: inline-block; }

        .ibm {
            img { width: 34px; height: 12px; }
        }
        .att {
            img { width: 39px; height: auto; }
        }
        .mb {
            img { width: 20px; height: 20px; }
        }
		.boa {
			img { width: 37px; height: 20px; }
		}
		.rolex {
			img { width: 39px; height: 21px; }
		}
        .ibm, .att, .mb, .boa, .rolex{
            display: none;
            &.show{display: block;}
        }
    }
	
	#eventLogo {
		//position: absolute;
		background: url(/assets/images/nav/anwa_logo.png) no-repeat; background-size: 116px 50px;
		width: 116px;
		height: 50px;
		// background-position: center;
		// left: 0;
		// right: 0;
		// margin: auto;
		// z-index: 10;
		display:flex;
		align-items: center;

		a {
			display: block;
			width: 116px;
			height: 50px;
			text-indent: -9999px;
		}
	}


	@include breakpoint(medium) {
		padding-left: 0px;
		height:$lg-header-height;
		flex-wrap: nowrap;
		padding:0px;
		align-items: center;
		padding:0px $sm-content-margin;
		.sponsors { z-index: z($elements, header); margin-top: 0; width: 75px; order:1; margin-top:30px;
            .ibm {
              img { width: 50px; height: 18px; }
            }
            .att {
              img { width: 54px; height: auto; }
            }
            .mb {
              img { width: 28px; height: 28px; }
            }
			.boa {
				img { width: 52px; height: 28px; }
			}
			.rolex {
				img { width: 50px; height: 27px;}
			}
		}
		
		#eventLogo {
			background-size: 142px 61px;
			width: 142px;
			height: 61px;
			position: absolute;
			left: 0;
			right: 0;
			//top:30px;
			margin: auto;

			a {
				width: 142px;
				height: 61px;
			}
		}
	}

	//amb03012021 - header nav clashes with logo
	@media only screen and (min-width: calc((992/16)*1em)) and (max-width: calc((1439/16)*1em)) {
		#eventLogo {
			left: 47%;
			right: 50%;
		}
	}

	@include breakpoint(large) {
		.sponsors { z-index: z($elements, header); margin-top: 35px; width: 75px; }

		#eventLogo {
			position: absolute;
			background: url(/assets/images/nav/anwa_logo.png) no-repeat; background-size: 220px 94px;
			width: 220px;
			height: 94px;
			background-position: center;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 10;

			a {
				display: block;
				width: 220px;
				height: 94px;
				text-indent: -9999px;
			}
		}
	}

	header.smaller & {

		height: $mobile-header-height-scroll;
		padding-top:0px;
		padding-bottom: 0px;
		align-items: center;
		align-content: center;
		position: relative;

		.sponsors {
			margin-top:4px;
		}
		@include breakpoint(medium) {
			.sponsors {
				margin-top: 0;
			}

			#eventLogo {
				background: url(/assets/images/nav/anwa_logo_small.png) no-repeat; background-size: 47px 42px;
				width: 47px;
				height: 42px;
				margin-top:auto;
				a {
					width: 47px;
					height: 42px;
				}
			}
		}

		@include breakpoint(large) {
			#eventLogo {
				background: url(/assets/images/nav/anwa_logo_small.png) no-repeat; background-size: 51px 46px;
				width: 51px;
				height: 46px;

				a {
					width: 51px;
					height: 46px;
				}
			}
		}
	}
}

header {
	.flex-filler {
		display:none;
	}
	.hamburger-wrapper {
		display:flex;
		//align-items: flex-end;
		align-items: center;
		width:25%;
		justify-content: flex-start;
		// position:relative;
		// top:20px;
		margin-top: -5px;
	}
	.hamburger {
		position: relative;
		text-decoration: none;
		text-transform: uppercase;
		color: $dark_green;
		font-weight: 400;
		font-style: normal;
		font-family: 'Benton Sans',BentonSans,Arial,Helvetica,sans-serif;
		cursor: pointer;
		width: 30px;
		height: 30px;
	
		div {
			position: absolute;
			height: 2px;
			width: 30px;
			background: #00ac69;
			display: block;
			content: " ";
			left: 0;
			top: 10px;
			transition: .5s transform ease-out;
			transition: .5s transform ease-out,.5s -webkit-transform ease-out;
	
			&:after {
			position: absolute;
			height: 2px;
			width: 30px;
			background: $dark_green;
			display: block;
			content: " ";
			left: 0;
			top: 10px;
			transition: .5s transform ease-out;
			transition: .5s transform ease-out,.5s -webkit-transform ease-out;
			}
		}
	}
	
	.hamburger-wrapper.active { 
		position:fixed;
		top:0px;
		left:0px;
		height:108px;
		width:100%;
		max-width: $max_mobile_nav_width;
		background:#FFFFFF;
		display:flex;
		align-items: center;
		padding:0px $sm-content-margin;
		z-index: 1;
		border-bottom:1px solid #d9d9d6;
	
		.hamburger div {
			transform: rotate(45deg) translate(2px,2px);
			&:after { transform: rotate(-90deg) translate(10px,0); top:10px;}
			&, &:after { transition: 0.5s transform ease-out; }
		}
	
		~ nav.mainmenu {
			position:fixed;
			top:0px;
			left:0px;
			display:flex;
			background:rgba(0,0,0,0.5);
			display:block;
			width:100vw;
			height:100%;
			max-height: 100vh;
			overflow-y:hidden;
			ul { 
			height: auto; 
			margin-bottom: 0; 
			margin-left: 0; 
			//margin-top:60px;
			display:flex;
			flex-direction: column;
			background-color: #FFFFFF;
			height:100%;
			max-height: 100vh;
			overflow-y:auto;
			max-width:$max_mobile_nav_width;
			padding-top:103px;
			}
	
			li { 
			display: block;
			width:100%;
			height:60px;
			line-height: 60px;
			min-height: 60px;
			margin:0px;
			white-space: nowrap;
			@include bentonsans(medium);
			padding:0px 18px;

			.moreLink{display:none}
	
			&:first-child {
				a {
				border-top:none;
				}
				
			}
			a { 
				display: block; 
				line-height: 60px;;
				top:0px;
				//width:calc(100% - (#{$sm-content-margin} * 2));
				//width:100%;
				height:100%;
				border-top:1px solid #f7f7f7;
				margin:0px auto;
				text-decoration: none;
			}
			&.selected {
				border-left: solid 3px $lt_green;
				padding-left:15px;
				a { 
				//margin-left: 15px - 3px; 
				color:$lt_green;
				}
			}
			}
		}

		~ .flex-filler {
			display:block;
			width:25%;
		}
	}

	#eventDate {
		color: $nav_text;
		font-size: px2rem(11);
		font-weight: 500;
		white-space: nowrap;
		width:100%;
		text-align: center;
		padding:0px;
	}

	&.smaller {
		.hamburger-wrapper {
			margin-top: 4px;
		}
		#eventLogo {
			background: url(/assets/images/nav/anwa_logo_small.png) no-repeat; background-size: 33px 29px;
			width: 33px;
			height: 29px;
			margin-top:-20px;
			a {
				width: 33px;
				height: 29px;
			}
		}
		#eventDate {
			position: absolute;
			bottom: 10px;
			left: 0px;
			right: 0px;
			margin: 0px auto;
			padding: 0px;
		}
	}

	@include breakpoint(medium) {
		.hamburger-wrapper {
			display:none;
		}

		#eventDate {
			padding:0px;
			padding-right:30px;
			height:37px;
			line-height: 37px;
			border-right: solid 1px $nav_border;
			font-size: px2rem(12);
			width:auto;
			order:0;
			margin-top:30px;
		}

		&.smaller {
			#eventDate {
				margin-top: 0px;
				position: relative;
    			bottom: auto;
				left: auto;
				right: auto;
				margin: auto;
				padding-right: 30px;
			}
		}
	}
}
@include breakpoint(small,min) {
	#logout{
		cursor:pointer;
		float:right;
	}
	nav.mainmenu>ul>li{
		display:inline-block!important;
	}
}


