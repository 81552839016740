header {
    position: fixed;
    background-color: #FFFFFF;
    //height: $mobile-header-height;
    top: 0;
    z-index: 11;
	width: 100%;
	// display:flex;
	// flex-direction: column;
	//padding:$xs-content-margin 0px;
	align-items: center;
	justify-content: space-evenly;
	
	&.alert {
		//height: $mobile-header-height + $global-alert-height;
		&.smaller {
			//height: $mobile-header-height-scroll + $global-alert-height;
		}
	}

	&.smaller {
		//height: $mobile-header-height-scroll;
	}

	@include breakpoint(medium){
		//height: $medium-header-height;
		//flex-direction: row-reverse;
		//padding:0px $lg-content-margin;

		&.alert {
			//height: $medium-header-height-scroll + $global-alert-height;

			&.smaller {
				//height: $medium-header-height-scroll + $global-alert-height;
			}
		}

		&.smaller {
			//height: $medium-header-height-scroll;
		}

	}

	@include breakpoint(large){
		//height: $header-height;

		&.alert {
			//height: $header-height + $global-alert-height;

			&.smaller {
				//height: $header-height-scroll + $global-alert-height;
			}
		}

		&.smaller {
			//height: $header-height-scroll;
		}
	}

}
