/**
 * Stylesheet _pullquote.scss
 */

article {
    .text{
        p.pullquote{
            width: 100%;
            font-size: px2rem(20); 
            line-height: calc((28/21)*1em); 
            color: $dark_green; 
            position: relative;
            @include tiempos; 
            padding-bottom:.5em;
            &:last-child {padding-bottom:1em;}
            &:before { content: "\00201C"; position: absolute; left: -0.5em; color: $gray_b; top: 0.125em; font-size: px2rem(30);}

            .close-quote {
                &:after { content: "\201D"; position: absolute; color: $gray_b; margin-left: 5px; margin-top: 20px; font-size: px2rem(30);}
            } 
            
            .credit { display: block; margin-top: 7px; font-size: px2rem(14); color: $gray_b; @include bentonsans; line-height: 1.25em;}

            @include breakpoint(small) {
                padding-left: 1.95em; font-size: px2rem(20);
                &:before { font-size: px2rem(42); top: 0.225em; left: 0.2em; }
                .close-quote{
                    &:after{ font-size: px2rem(42); }
                }
            }

            @include breakpoint(medium) {
                font-size: px2rem(30);
                padding-left: 1.83em;
                &:before { font-size: px2rem(88); left: 0em; top: 0.25em; }
                .close-quote{
                    &:after{ font-size: px2rem(88); }
                }                
            }

            @include breakpoint(large) {
                font-size: px2rem(40); padding-left: 75px; padding-right: 80px; line-height: 1.39em;
                &:before { font-size: px2rem(102); top: 0.225em; left: 0.1em; }
                .close-quote{
                    &:after{ font-size: px2rem(102);}
                }
                .credit {font-size: px2rem(16);}
            }

            @include breakpoint(xlarge) {
                font-size: px2rem(46);
                padding-left: 1.69em;
                &:before { font-size: px2rem(120); top: 0.25em; left: 0.05em; }
                .close-quote{
                    &:after{ font-size: px2rem(120); }
                }
            }           
        }
    }
}
