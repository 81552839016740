.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    //padding: 24px 15px;

    * {
        box-sizing: border-box;
    }

    @include breakpoint(medium) {
        width: 920px;
        min-height: 418px;
        flex-direction: row;
        align-items: stretch;
        margin: 0 auto;
        //padding: 24px 0;
    }

    .white-underline, .webview-white-underline {
        padding-bottom: 6px;
        
        &:after {
            content: '';
            background-color: $white;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
        }
    }
    .fadeOverlay { // used on Login and Sign Up pages
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 40;
       // background-color: $black;
        opacity: 0.4;
        display: none;

        &.show {
            display: block;
        }
    }
}
.form-content {
    background-color: $white;
    @include breakpoint(small) {
        margin: 30px auto;
    }
    .two-columns {
        @include breakpoint(small,min) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        // .float-label, .fixed-label {
        //     width: 48%;
        // }

        &.long {
        //     .float-label, .fixed-label {
        //         width: 50%;
        //     }
            .birthdate-container, .ssn4-container {
                width: 100%;

                .float-label, .fixed-label {
                    width: 100%;
                }
            }
        }
    }
    .four-columns {
        @include breakpoint(small,min) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        // .float-label, .fixed-label {
        //     width: 22%;
        // }
    }

    @include breakpoint(small) {
        position: relative;
        width: 100%;
        margin-top: 20px;

        .two-columns {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-between;
    
            .float-label, .fixed-label {
                width: 48%;
            }
    
            &.long {
                .float-label, .fixed-label {
                    width: 50%;
                }
                .birthdate-container, .ssn4-container {
                    width: 50%;
    
                    .float-label, .fixed-label {
                        width: 100%;
                    }
                }
            }
        }
        .four-columns {
            // display: flex;
            // flex-direction: row;
            // justify-content: space-between;
    
            .float-label, .fixed-label {
                width: 22%;
            }
        }
    }

    .form-title {
        color: $dark_green;
        @include tiempos(medium);
        font-size: px2rem(21);

        @include breakpoint(medium) {
            font-size: px2rem(36);
        }
    }
    .actions {
        margin-top: 24px;

        @include breakpoint(medium) {
            margin-top: 30px;
        }
    }
    .form-text {
        font-size: px2rem(12);
        margin-top: 20px;

        @include breakpoint(medium) {
            font-size: px2rem(14);
        }
    }
    .bold-text {
        @include bentonsans(bold);
    }

    .float-label, .fixed-label, .readonly-label {
        display: block;
        position: relative;
        height: 46px;
        width: 100%;
        border: none;
        background-color: transparent !important;
        @include bentonsans(book);
        padding: 0;
        outline: none;
        margin-top: 20px;

        label {
            display: block;
            width: 100%;
            height: 100%;
        }
        i {
            position: absolute;

            &.icon-pwd-show, &.icon-pwd-hide, &.icon-help {
                right: 0;
                bottom: 8px;
                cursor: pointer;
                color: $gray_6;
                font-size: px2rem(24);
                text-align: center;
            }
            &.icon-help {
                bottom: 12px;
                font-size: px2rem(20);

                &.active {
                    color: $lt_green;
                }
            }
            &.icon-error {
                left: 0px;
                bottom: 10px;
                color: $bright_red;
                display: none;
            }
        }
        &.error {
            i {
                &.icon-error {
                    font-size: px2rem(12);
                    display: block;
                }
            }
        }

        @include breakpoint(medium) {
            height: 60px;
    
            i {
                &.icon-pwd-show, &.icon-pwd-hide, &.icon-help {
                    font-size: px2rem(28);
                }
                &.icon-help {
                    font-size: px2rem(24);
                }
                &.icon-error {
                    bottom: 14px;
                }
            }
        }
    }
    .float-label input, .fixed-label input:not(#react-select-2-input), .readonly-label input {
        
        width: 100%;
        height: 30px;
        border: none;
        background-color: transparent;
        @include bentonsans(book);
        font-size: px2rem(16);
        border-bottom: 1px solid #E6E6E6;
        padding: 0;
        outline: none;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0;
        color: $dark_green !important;
        caret-color: $dark_green;

        &:focus {
            color: $dark_green !important;
            caret-color: $dark_green;
            border-bottom: 2px solid $lt_green;
        }

        @include breakpoint(medium) {
            font-size: px2rem(21);
            height: 40px;
        }
    }
    .float-label.error input, .fixed-label.error input:not(#react-select-2-input) {
        caret-color: $bright_red;
        padding-left: 21px;
        border-bottom: 2px solid $bright_red;

        &:focus {
            caret-color: $bright_red;
        }
    }
    .float-label label {
        @include bentonsans(book);
        color: $gray_6;
        position: relative;
        margin-bottom: 15px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(18px);
        font-size: px2rem(16);
        transition-duration: 300ms;

        @include breakpoint(medium) {
            transform: translateY(26px);
            font-size: px2rem(18);
        }
    }
    .float-label:focus-within label, .float-label.Active label,
        .float-label input:-webkit-autofill ~ .input-label, .float-label input:focus ~ .input-label
            .float-label input:not(:placeholder-shown):not(:focus) ~ .input-label {
        transform: translate(0);
        font-size: px2rem(9);

        @include breakpoint(medium) {
            font-size: px2rem(14);
        }
    }
    .float-label .disabled-input {
        //color: #DDDDDD !important;
        color: $gray_b !important;
    }
    .required {
        position: static !important;
        color: $bright_red;
    }
    .instructions, .field-instructions {
        @include bentonsans(book);
        color: $gray_6;
        font-size: px2rem(12);
        display: block;
        margin-top: 5px;
        min-height: 20px;

        .bolder {
            @include bentonsans(medium);
        }

        @include breakpoint(medium) {
            height: 23px;
            font-size: px2rem(14);
        }
    }
    .field-instructions {
        transform: translate(0px, 60px);
    }
    .validation {
        @include bentonsans(book);
        color: $lt_green;
        font-size: px2rem(12);
        margin-top: 8px;
        min-height: 20px;

        i {
            font-size: px2rem(12);
            position: static;
            margin-right: 9px;
        }

        @include breakpoint(medium) {
            height: 23px;
            font-size: px2rem(14);
        }
    }
    .readonly-label input {
        color: $gray_6 !important;
    }
    .fixed-label select {
        border: none;

        option{ 
            padding: 0;
            @include bentonsans(book);
            font-size: px2rem(16);
            color: $gray_6;
            white-space: normal;
        }

        @include breakpoint(medium) {
            option{ 
                font-size: px2rem(21);
            }
        }
    }
    .prefix-field, .phone-field {
        @include breakpoint(small) {
            width: 48%;
        }
    }
    .fixed-label label, .readonly-label label {
        font-size: px2rem(9);
        @include bentonsans(book);
        color: $gray_6;
        pointer-events: none;

        @include breakpoint(medium) {
            font-size: px2rem(14);
        }
    }
    .error-field {
        background-color: $off_white;
        width: calc(100% + 15px);
        display: flex;
        flex-direction: column;
        margin-left: -15px;
        // margin-top: -24px;
        margin-bottom: 34px;
        padding: 12px 12px;
        color: $black;

        .error-item {
           @include bentonsans(book);
           font-size: px2rem(12);
           margin: 1px 0;
           display: flex;
           justify-content: center;
           align-items: center;
           
           i {
                color: $bright_red;
           }
           .error-text {
               margin-left: 9px;
           }
        }

        @include breakpoint(medium) {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 34px;
        }
    }
    .form-instructions {
        @include bentonsans(book);
        font-size: px2rem(12);
        color: $gray_6;
        margin-bottom: 15px;

        @include breakpoint(medium) {
            font-size: px2rem(14);
        }
    }
    .correspondenceOptIn, .termsAgree {
        margin-top: 24px;
        @include bentonsans(book);
        font-size: px2rem(12);
        color: $gray_6;

        a {
            text-decoration: none;
            @include bentonsans(medium);
            color: $lt_green;
            outline: none;

            &:focus {   
                border:2px solid #006747;
            }
        }

        @include breakpoint(medium) {
            font-size: px2rem(14);
        }
    }
    .select-box{ 
        label {
            position: absolute;
            top: 0;
        }
        .react-select__control, select{
            position: relative;
            @include bentonsans(book);
            -webkit-appearance: none;
            -moz-appearance:none;
            border: none;
            border-bottom: 1px solid #E6E6E6;
            height: 30px;
            min-height: 30px;
            width: 100%;
            margin: 0;
            background:transparent;
            font-size: px2rem(21);
            padding-left: 0;
            border-radius: 0;
        }
        .react-select__indicator-separator {
            display: none;
        }
        select::-ms-expand {
            display: none;
        }
        .select_down_arrow { 
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 0;
            height: 30px;

            &:after { position: absolute; top: 14px; right: 5px; @include border-arrow(10px, 5px, $dark_green, bottom); }
            &.gray_8 {
              &:after { @include border-arrow(8px, 4px, $gray_6, bottom); }
            }
    
            .active &, &.active, .open &, &.open {
              &:after { transform: rotate(180deg); transition: 0.15s transform ease-in-out; }
            }
        }
        .react-select-container {
            height: 30px;
            width: 100%;
            color: $dark_green;
            position: absolute;
            bottom: 0;
    
            .react-select__control--is-focused {
                border-bottom: 2px solid $lt_green;
                box-shadow: none;
            }
            input {
                margin-top: 0;
            }
            .react-select__value-container, .css-1hwfws3 {
                height: 100%;
                padding: 0;
                @include bentonsans(book);
                font-size: px2rem(16);

                // pulled from react-select styles
                -webkit-box-align: center;
                align-items: center;
                display: flex;
                flex: 1 1 0%;
                flex-wrap: wrap;
                padding: 2px 8px 2px 0;
                position: relative;
                overflow: hidden;
                box-sizing: border-box;

                .react-select__single-value, .react-select__input {
                    color: $dark_green;
                    top: unset;
                    transform: none;
                    bottom: 5px;
                }
                // pulled from react-select styles
                .css-1uccc91-singleValue {
                    margin-left: 2px;
                    margin-right: 2px;
                    max-width: calc(100% - 8px);
                    overflow: hidden;
                    position: absolute;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    box-sizing: border-box;
                }
            }
            .react-select__placeholder {
                margin: 0;
                color: $gray_6;
                transform: none;
                top: unset;
                bottom: 3px;
            }
            .css-b8ldur-Input {
                margin: 0;
                padding: 0;
            }
            .react-select__indicators {
                display: none;
            }
            .react-select__menu {
                margin-top: 0;
            }
            .react-select__option {
                width: 85%;
                margin: 0 auto;
                height: 40px;
                line-height: 27px;
                border-bottom: 1px solid $gray_d;
                font-size: px2rem(13);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
        .css-1laao21-a11yText, #aria-selection-event, #aria-context { display: none !important; }

        // pulled from react-select styles
        .react-select__menu, .css-26l3qy-menu {
            top: 100%;
            background-color: rgb(255, 255, 255);
            border-radius: 4px;
            box-shadow: rgba(0, 0, 0, .1) 0px 0px 0px 1px, rgba(0, 0, 0, .1) 0px 4px 11px;
            margin-bottom: 8px;
            margin-top: 8px;
            position: absolute;
            width: 100%;
            z-index: 1;
            box-sizing: border-box;

            .react-select__menu-list, .css-11unzgr {
                max-height: 300px;
                overflow-y: auto;
                padding-bottom: 4px;
                padding-top: 4px;
                position: relative;
                box-sizing: border-box;
            
            }
        }
        // pulled from react-select styles
        .css-62g3xt-dummyInput {
            background: 0;
            border: 0;
            font-size: inherit;
            outline: 0;
            padding: 0;
            width: 1px;
            color: transparent;
            left: -100px;
            opacity: 0;
            position: relative;
            transform: scale(0);
        }
        &.error {
            .react-select-container {
                .react-select__value-container {
                    padding-left: 21px;
                }
            }
        }

        @include breakpoint(medium) {
            .react-select__control{
                font-size: px2rem(21);
                height: 40px;
            }         
            .react-select-container {
                height: 40px;

                .react-select__value-container {
                    font-size: px2rem(21);
                }
                .react-select__placeholder {
                    // top: 34%;
                }
            }
            .select_down_arrow { 
                height: 40px;
            }
            .select_down_arrow {
                &:after { top: 20px; }
            }
        }
    }
    .checkbox-option {
        position: relative;
        display: inline-block;
        
        input + label {
            position: relative;
            padding-left: 32px;
            top: 0;
            cursor: pointer;
            text-align: left;
        }
        input:checked + label:after {
            opacity: 1;
        }
        input {
            display: none;

            /** for accessibility purpose - need to be able to select using keyboard */
            &#mainAddress, &#onlyResident, &#permanentAddress, &#jrPassThu, &#jrPassFri, &#jrPassSat, &#jrPassSun {
                display: inline-block;
                position: absolute;
            }
        }
        label {
            text-align: right;
            font-family: unset;
                
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: -1px;
                width: 18px;
                height: 18px;
                border: 1px solid $gray_6;
                background:$white;
                border-radius: 3px;
            }
            &:after {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 20px;
                height: 20px;
                background-color: $lt_green;
                background-image: url(/assets/images/misc/white-checkmark.svg);
                background-repeat: no-repeat;
                background-size: 13px 11px;
                background-position: 3px 4px;
                border-radius: 3px;
                opacity: 0;
            }
            &:hover {
                &:before {
                    box-shadow: inset 0 0 0 1px $lt_green;
                }
            }
        }

        @include breakpoint(medium) {
            label {
                font-size: px2rem(14);
            }
        }
    }
    .radio-option {
        position: relative;
        display: inline-flex;
        align-items: center;

        i {
            z-index: 11111111;
            position: relative;
            background: white;
            font-size: 22px;
            color: $black;
            margin-right:10px;
            &.icon-radio-checked {
                //color: $lt_green;
            }
        }
        
        input + label {
            position: relative;
            padding-left: 0;
            top: 0;
            cursor: pointer;
            text-align: left;
        }
        input:checked + label:after {
            opacity: 1;
        }
        input {
            display: none;

            /** for accessibility purpose - need to be able to select using keyboard */
            &#jrPassIntendToUseY, &#jrPassIntendToUseN {
                display: inline-block;
                position: absolute;
            }
        }
        label {
            text-align: right;
            font-family: unset;
            padding-left:0px;
            display: inline-flex;
            align-items: center;
                
            &:before { display:none;
                content: '';
                position: absolute;
                left: 0;
                top: -1px;
                width: 18px;
                height: 18px;
                border: none;
                background:$white;
            }
            &:after { display:none; }
            &:hover {
                &:before {display:none;
                    //box-shadow: inset 0 0 0 1px $lt_green;
                }
            }
        }

        @include breakpoint(medium) {
            label {
                font-size: px2rem(14);
            }
        }
    }
    .helpToolTip {
        width: 158px;
        height: 61px;
        @include bentonsans(book);
        font-size: px2rem(10);
        color: $gray_6;
        position: absolute;
        right: 0;
        bottom: 46px;
        cursor: pointer;
        padding: 12px;
        background: $white;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .3);
        line-height: 16px;

        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            bottom: -6px;
            right: 12px;
            box-sizing: border-box;
                
            border: 3px solid black;
            border-color: transparent $white $white $white;
                
            transform-origin: 0 0;
            transform: rotate(-45deg);
                
            box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, .2);
        }
    }
    // .action-buttons {
    //     width: 100%;
    //     margin-top: 24px;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: flex-start;

    //     .button-primary {
    //         width: 100%;
    //     }
    //     .cancel-link {
    //         @include bentonsans(medium);
    //         font-size: px2rem(14);
    //         color: $lt_green;
    //         cursor: pointer;
    //         text-align: center;
    //         margin-top: 30px;
    //     }

    //     @include breakpoint(small) {
    //         margin-top: 30px;
    //         flex-direction: row;
    //         align-items: center;
                
    //         .button-primary {
    //             width: 230px;
    //             margin-top: 0;
    //         }
    //         .cancel-link {
    //             margin-left: 24px;
    //             margin-top: 0;
    //             text-align: left;
    //         }
    //     }
    // }
    .divider-line {
        width: 100%;
        height: 1px;
        background-color: #E6E6E6;
        margin: 20px 0;
    }
    .account-email {
        height: 46px;
        line-height: 56px;

        @include breakpoint(medium) {
            height: 60px; 
            line-height: 70px;
        }
    }
    .keep-notified-container, .checkbox-option, .message-content, .delete-account-container {
        position: relative;
        display: flex;

        .message-content {
            margin-left: 30px;
            margin-top: -2px;
        }
    }
    #VerifyIdentityForm{
      .verificationcode-field, .float-label{
        max-width: 273px;
      }  
      .options{
        display: flex;

        .selectVerificationMethod, .radio-option{
          i {color: #006747; margin-right: 0px;}
        }

        span{
          display: flex;
          //margin-right: 30px;
          //align-items: baseline;
          align-items: center;
          margin-left:20px;

          &:first-child {margin-left:0px;}
          .checkbox-option{
            
          }
          .message-content{
            position: unset;
            // margin-left: 30px;
            @include bentonsans(medium);
            font-size: px2rem(12);
            color: $lt_green;
            text-decoration: none;
            padding:0px 10px;

            .jsx-parser{
              margin-left: 3px;
            }
          }
        }
      }
      .verificationFailed{
        .message-content{
          .jsx-parser{
            span, .red-text{
              padding: 0px;
              margin-right: 5px;
            }
          }
        }
        .icon-error{
          color: #DF2F3B;
        }
      }
      
    }
    .verfiyIdentiy-btn{
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .button, .action-link{
        margin-right: 20px;
        padding: 0px 10px;
      }
    }
}

.page-actions, .page-links { 
    margin-top: 40px;

    .action-link-container {
        margin-top: 30px;
        text-align: center;
    }
    a.action-link {
        @include bentonsans(medium);
        font-size: px2rem(12);
        color: $lt_green;
        text-decoration: none;
        cursor: pointer;
    
        @include breakpoint(medium) {
            font-size: px2rem(14);
        }

        &:focus {   
            border:2px solid #006747;
        }
    }

    @include breakpoint(small) {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action-link-container {
            margin-left: 24px;
            margin-top: 0;
            text-align: left;
        }

    }
    .separator-with-word {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 20px 0;
      // @include bentonsans(medium);
      //   font-size: px2rem(14);
    }
    .separator-with-word:before, .separator-with-word:after{
      content: "";
      flex: 1 1;
      border-bottom: 1px solid #B1B3B3;
      margin: auto;
    }
    .separator-with-word:before {
      margin-right: 10px
    }
    .separator-with-word:after {
      margin-left: 10px
    }
}

.account-information-container {
    .page-actions, .page-links { 
        margin-top:60px;
    }
}

@include breakpoint(small) {
    .page-links {
        margin-top: 5px;
        flex-direction: column;
    }
}

@include breakpoint(medium) {
    .form-content {
        .password-field {
            &.new {
                margin-top: 0;
            }
            &.confirm {
                margin-top: 20px;
            }
        }
    }
}

// .form-container, .account-detail, .welcome-module {
//     .button-primary {
//         width: 100%;
//         border-width: 1px;
//         border-style: solid;
//         height: 50px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: px2rem(14);
//         text-decoration: none;

//         &.green {
//             background-color: $white;
            
//             &.active {
//                 border-color: $dark_green;
//                 color: $dark_green;
//                 cursor: pointer;
//             }
//         }
//         &.white {
//             background-color: transparent;
//             border-color: $white;
//             color: $white;
//             opacity: .3;
            
//             &.active {
//                 opacity: 1;
//                 cursor: pointer;
//             }
//             &:hover {
//                 outline: 1px solid $white;
//             }
//         }
//         &.disabled {
//             border-color: $disabled_color;
//             color: $disabled_color;
//             cursor: default;
//         }
//     }
// }

// .account-detail {
//     .button-primary {
//         &.green {
//             &:hover, &:active {
//                 border-color: $lt_green;
//                 color: $lt_green;
//             }
//         }
//     }
// }

// .welcome-module {
//     width: 100%;
//     height: 100%;

//     .welcome-image {
//         height: 450px;
//         background-image: url(/assets/images/login/welcome_hero.jpg);
//         background-size: cover;
//     }
//     .welcome-content {
//         width: 100%;
//         height: calc(100vh - 654px);
//         background-color: $white;
//         text-align: center;
//         padding-top: 48px;
//         padding-bottom: 30px;

//         .title-text {
//             @include tiempos(medium);
//             font-size: px2rem(46);
//             color: $dark_green;
//         }
//         .content-text {
//             @include bentonsans(book);
//             margin-top: 16px;
//             margin-bottom: 36px;
//             line-height: 24px;
//             color: $gray_6;
//         }
//         .loading-container {
//             position: relative;
//         }
//     }
//     .button-primary {
//         width: 315px;
//         margin: 0 auto;
//     }
// }

// .webview {
//     .login-content {
//         margin-top: 0;
//     }
// }

.green-text {
    color: $lt_green;
}

#activation_code {
    text-transform: uppercase;
}

.message-buttons {
    margin-top: 30px;

    &.centered {
        a {
            margin: 0 auto;
        }
    }
}

.return-error {
    color: $bright_red;
}


input.button.submit, div.button, .page-actions .button {
    &:focus {
        outline: solid 2px #006747;
    }
}
.page-actions-container {
  margin: 40px 0px;
  
  .page-actions{
    margin-top: unset;
  }
  
  a {
    margin-right: 24px;
  }
}
