.stub {
    .page-grid {
        padding: 0;

        @include breakpoint(small) {
            padding:0px $sm-content-margin;
        }
    }

    .hero {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        width: 100%;
        height: 375px;
        display: flex;
         align-items: center;
         justify-content: center;


        @include breakpoint(small) {
            background-position: center;
            height: 400px;
        }

        @include breakpoint(medium) {
            background-position: top;
            height: 727px;
        }

        @include breakpoint(xlarge) {
            height: 777px;
        }
    }

    .stub_message {
        width: 70%;
        text-align: center;

        @include breakpoint(small) {
            width: 50%;
        }
    }

    .stub_date {
        @include bentonsans;
        font-size: px2rem(12);
        font-weight: 500;
        text-transform: uppercase;
        color: $yellow;

        @include breakpoint(medium) {
            font-size: px2rem(18);
        }
    }

    .stub_title {
        @include tiempos;
        font-size: px2rem(21);
        font-weight: 500;
        color: $white;

        @include breakpoint(medium) {
            font-size: px2rem(36);
        }
    }
}