

@mixin currentSplashStyle($current) {
  // this block is for steps not completed
  &[data-step="#{$current}"] {
    ul li:nth-child(n+#{$current}) {
    //ul li:nth-child(-n+#{$prev}) {
      &:before {
        color: #999999;
        background-color: #CCCCCC;
        border: 1px solid #999999

      }
    }
    // this block is for the current step
    ul li:nth-child(#{$current}) {
      &:before {
        color: $step-circle-color;
        background-color: #FFFFFF;
      }
    }
  }
  
}

.stepSplash{
  p{
    line-height: 2.5rem;
  }
  ul{
    list-style-type: none;
    counter-reset: li;
    font-size: 14px;
    line-height: 24px;
    margin: 20px 0px;
    // padding-left: 10px;
    li{
      position: relative;
      padding: 5px 0 5px 30px;
      &::before {
        content: counter(li);
        counter-increment: li;
        height: 20px;
        width: 20px;
        border: 1px solid $step-circle-color;
        border-radius: 50%;
        background-color: $step-circle-color;
        color: #fff;
        text-align: center;
        position: absolute;
        left: 0;
        //top: 3px;
        z-index: 5;
      }
      &::after{
        position: absolute;
        content: '';
        width: 2px;
        background-color: $step-circle-color;
        height: 100%;
        left: 10px;
        bottom: -5px;
      }
    }
    li:last-child::after{
      display: none;
      }
  }
  @include currentSplashStyle("1");
  @include currentSplashStyle("3");
  @include currentSplashStyle("4");
  @include currentSplashStyle("5");
  // &[data-step="3"] {
  //   ul li:nth-child(3) {
  //     &:before {
  //       color: $step-circle-color;
  //       background-color: #FFFFFF;
  //     }
  //   }
  // }
}
  
.stepsOverview{
  display: flex;
  text-align: center;
  max-width: 992px;
  width: 92%;
  margin-top: 18px;
  .stepContainer{font-size: 12px; width: 96px;}
}
#article-content .stepsOverview{margin: auto;}
.progressWrapper{display: flex; margin-bottom: 20px}
.stepContainer .circleWrapper, .circleLabel {position: relative; padding: 8px 50px 8px 0px;} // padding: 10px 20px

.stepContainer:not(:last-child) > .circleWrapper::after{
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #CCCCCC;
  margin: auto;
  z-index: -1;
}
.stepContainer:not(:last-child).active > .circleWrapper::after{
  background-color: $step-circle-color;
  background-color: $step-circle-color;
}
.stepContainer:not(:last-child).completed > .circleWrapper::after{
  background-color: $step-circle-color;
}

.circle{
  font-size: 18px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background-color: #CCCCCC;
  color: #999999;
  border: 1px solid #999999;
  border-radius: 50%;      
}
.active .circle {
  background-color: #fff;
  color: $step-circle-color;
  border-color: $step-circle-color;
}
.completed .circle {
  background-color: $step-circle-color;
  color: #fff;
  border-color: $step-circle-color;
}
  //font-size: 12px;

  // ul{
  //   list-style-type: none;
  //   counter-reset: li;
  //   line-height: 30px;
  //   display: flex;
  //   li{
  //     position: relative;
  //     padding: 5px 30px 5px 0px;
  //     &::before{
  //       display: flex;
  //       margin: 0 auto;
  //       content: counter(li);
  //       counter-increment: li;
  //       height: 30px;
  //       width: 30px;
  //       line-height: 25px;
  //       border: 1px solid $step-circle-color;
  //       border-radius: 50%;
  //       background-color: #fff;
  //       color: $step-circle-color;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //     // &::after{
  //     //   content: '';
  //     //   width: 2px;
  //     //   background-color: $step-circle-color;
  //     //   height: 100%;
  //     //   left: 10px;
  //     //   bottom: -5px;
  //     // }
      
  //   }
    
  // }
    
    // .stepBlock .circlWrapper {
    //   padding: 0px 20px;
    // }
