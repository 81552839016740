.ticket-application{
    font-weight: normal;
    margin-bottom:30px;
    .header {
        @include tiempos(medium);
        color: #006747;
        margin-bottom: 15px;
        font-size: px2rem(20);
    }
    table {
        //margin-top:20px;
        @include bentonsans(medium);
        border-collapse: collapse;
        width:100%;
        th {
            background-color: #ededed;
            @include bentonsans(medium);
            &.highlight {
                background-color: #006747;
                color: #FFFFFF;
            }
        }
        td, th {
            border: 1px solid #d3d3d3;
            padding: 0.5rem;
            text-align: center;
      }
      td {
            @include bentonsans();
            vertical-align: middle;
            &:nth-child(1){
                @include bentonsans(medium);
                width:210px;
            }
        }
    }
    .ticket-title{
        margin-top: 50px;

        p {
            font-size:px2rem(12);
            color:#6B6F73;
            font-weight:100;
            padding-bottom:0px;
            @include bentonsans();
        }
    }
    .titleRow {
        th {background-color: transparent; }
        span {display:block; padding:0px;}
        .title {text-transform: uppercase; font-size: px2rem(18px);}
        .subtitle {font-weight: normal; font-size: px2rem(14px)}
    }
}