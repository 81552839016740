

// Root font-size:
$root-font: 16px;

// Sizes
$sizes: (2, 4, 8, 16, 32, 64, 80, 128, 160, 256);

// Buttons
$button-sizes: (xs, sm, md, lg, xl);
$button-font: (12, 14, 20, 24, 32);
$button-padV: (8, 16, 24, 32, 64);
$button-padH: (16, 32, 48, 64, 128);


// spacing, margins, and heights used for layouts
$mobile-sb-height: 15px;
$medium-sb-height: 30px;
$medium-sb-height-scroll: 16px;
$sb-height: 25px;

// alert height
$global-alert-height: 40px;


// header heights
$mobile-header-height: 108px;
$mobile-header-height-scroll: 72px;
$medium-header-height: 152px;
$medium-header-height-scroll: 72px;
$header-height: 152px;
$header-height-scroll: 92px;
$header-height-alert : $header-height + $global-alert-height;
$mobile-sb-height-alert : $mobile-sb-height + $global-alert-height;

$lg-header-height:132px;


// nav heights and widths
$mobile-nav-height: 108px;
$mobile-nav-height-scroll: 72px;
$medium-nav-height: 91px;
$medium-nav-height-scroll: 106px;
$nav-height: 94px;
$nav-height-scroll: 46px;

$nav-width: 385px;
$mobile-nav-width: 280px;
$max-mobile-nav-width: 375px;

// don't have mini LB on ANWA but vars are used in golf-component scss for the Masters
// adding them
$header: $mobile-nav-height;
$header-sb: $mobile-nav-height + $mobile-sb-height;
$header-sb-alert: $mobile-nav-height + $mobile-sb-height + $global-alert-height;
$header-alert: $mobile-nav-height + $global-alert-height;

// breakpoint medium
$header-lg: $nav-height;
$header-alert-lg: $nav-height + $global-alert-height;
$header-sb-lg: $nav-height + $sb-height;
$header-sb-alert-lg: $nav-height + $sb-height + $global-alert-height;


$content-height-noMiniLB: calc(100vh - #{$nav-height});


$mobile-columns: 6;

$mobile-footer-height: 236px;
$footer-height: 176px;
$mobile-header-footer-height: $header-height + $mobile-footer-height;
$header-footer-height: $header-height + $footer-height;
$max-content-width: 1440px;
$content-height: calc(100vh - #{$header-height});

$sm-content-margin: 15px;
$lg-content-margin: 30px;

$xs-content-margin: calc(1.69492% * 2);
$sm-content-margin: 1.69492%;  // not sure how 2018 came up with percentage margin, but using here to match - awt
$lg-content-margin: 1.69492%;

$three-col-gutter-width-sm:18px;
$three-col-gutter-width-sm-ie11:14px;
$three-col-gutter-width-lg:24px;
$three-col-gutter-width-lg-ie11:20px;

$four-col-gutter-width-sm:12px;
$four-col-gutter-width-lg:38px;

$two-col-gutter-width-sm:18px;
$two-col-gutter-width-sm-ie11:14px;
$two-col-gutter-width-lg:24px;
$two-col-gutter-width-lg-ie11:20px;

$one-col-gutter-width-sm:18px;
$one-col-gutter-width-lg:24px;

$article-max-content: 992px;
$content-width-sm: 89.97175%;
$content-width-md: 92.05508%;
$content-width-lg: 64.97175%;