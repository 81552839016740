
// show tabs under medium breakpoint (992px)
// show dropdown above medium breakpoint (992px)

.select-menu-tabs2dropdown {
    width: 100%;
    position: relative;
    text-align: left;

    button {
        background: transparent;
    }

    .title{
        display: none;

        @include breakpoint(medium) { // 992px
            display: block;
            background: none;
            display: inline-block;
            position: relative;
            width: auto;
            height: 30px;
            border: 0px;
            color: $dark-green;
            text-decoration: none;
            text-align: left;
            text-transform: uppercase;
            padding: 0 30px 0 0;
            opacity: 1;
            cursor: pointer;
            @include tiempos;
            font-size: #{px2rem(18px)};

            &.border {
                border: 1px solid $dark-green;
            }

            .menu-arrow {
                font-size: 18px;
                line-height: 10px;
            }
        } // breakpoint(medium)
    }

    &-dropdown{
        display: block;
        box-shadow: none;
        position: relative;
        width: 100%;
        border-bottom: 2px solid $lt_green;
        font-size: #{px2rem(11px)};

				.option {
						@include bentonsans(medium);
						border-top: 1px solid $gray_d;
						border-right: 1px solid $gray_d;
						border-bottom: none;
						border-left: 1px solid transparent;
						background: $white;
						color: $dark_green;
						font-size: #{px2rem(11px)};
						line-height: #{px2rem(35px)};
						padding: 0 18px;
						margin: 0;
						width: auto;

						&:first-child {
							border-top: 1px solid $gray_d;
							border-left: 1px solid $gray_d;
						}

						&.selected {
							background: $lt_green;
							color: $white;
							border-top: 1px solid $lt_green;
							border-left: 1px solid $lt_green;
							border-right: 1px solid $lt_green;

								&:before {
										background: none;
								}
						}

						&:hover {
								background: $lt_green;
								color: $white;
								border-top: 1px solid $lt_green;
								border-left: 1px solid $lt_green;
								border-right: 1px solid $lt_green;
								cursor: default;
						}
				}

        @include breakpoint(medium) { // 768px

            background: $white;
						border-bottom: 1px solid $gray_d;
            position: absolute;
            z-index: 2;
            display: none;
            min-width: 175px;
            padding: 0;
            max-height: 400px;
            width: auto;
            @include dd-box-shadow;

            &.show {
                display: block;
            }

            .option{
                @include bentonsans(semibold);
                display: block;
                border-top: 1px solid $gray_e;
								border-right: none;
								border-left: none;
                color: $dark-green;
								font-size: #{px2rem(15px)};
                line-height: normal;
                margin: 0;
                padding: 10px 15px;;
                text-align: left;
                width: 100%;

								&:first-child {
									border-left: none;
								}

                &.selected{
										border-top: 1px solid $gray_e;
										border-right: none;
                    border-bottom: none;
										border-left: none;
                    color: $lt_green;

                    &:before {
                        content: '';
                        width: 3px;
                        height: 100%;
                        background-color: $lt_green;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                    }

                }

								&:hover {
										border-top: 1px solid $gray_d;
										border-left: none;
										border-right: none;
										cursor: default;
								}
            }

            button {
                flex: 0 0 auto;
                position: relative;
                white-space: nowrap;
                @include bentonsans(semibold);
                font-size: 15px;

                text-decoration: none;
                border: 0;
                cursor: pointer;
                width: 100%;

                &:first-child {
                    border-top: none;
                }
            }

            .section {
                text-align: center;
                border-bottom: 1px solid $dark-green;
            }

        } // breakpoint(medium)
    } // -dropdown

    .navigation_down_arrow { position: relative;
        &:after { position: absolute; top: 15px; right: 5px; @include border-arrow(10px, 5px, $dark_green, bottom);
        }
        &.gray_8 {
          &:after { @include border-arrow(8px, 4px, $gray_6, bottom); }
        }

        .active &, &.active, .open &, &.open {
          &:after { transform: rotate(180deg); transition: 0.15s transform ease-in-out; }
        }
    }

}
