.loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
    @keyframes spin {
        to {
            transform: rotate(1turn);
		}
    }
    .progress {
        position: relative;
        display: inline-block;
        width: 5em;
        height: 5em;
        margin: 0 .5em;
        font-size: 12px;
        text-indent: 999em;
        overflow: hidden;
        animation: spin 1s infinite steps(8);
    }
    .small.progress {
        font-size: 6px;
    }
    .large.progress {
        font-size: 12px;
        margin: 0 auto;
    }
    .progress:before,
    .progress:after,
    .progress>div:before,
    .progress>div:after {
        content: '';
        position: absolute;
        top: 0;
        left: 2.25em;
        /* (container width - part width)/2  */
        width: .5em;
        height: 1.5em;
        border-radius: .2em;
        background: #eee;
        box-shadow: 0 3.5em #eee;
        /* container height - part height */
        transform-origin: 50% 2.5em;
        /* container height / 2 */
    }
    .progress:before {
        background: #555;
    }
    .progress:after {
        transform: rotate(-45deg);
        background: #777;
    }
    .progress>div:before {
        transform: rotate(-90deg);
        background: #999;
    }
    .progress>div:after {
        transform: rotate(-135deg);
        background: #bbb;
	}



	$circle-color: $dark_green;
	$circle-size: 22px;
	$circle-space: 4px;
	$circle-size-small: 15px;
	$circle-space-small: 3px;

	@keyframes animation-size {
		50% {
			transform: scale(0.70);
			opacity: 0.2;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}
	
	.circle-one {
		background-color: $circle-color;
		width: $circle-size;
		height: $circle-size;
		margin: $circle-space;

		display: inline-block;
		border-radius: 50%;
		animation: animation-size 0.8s linear 0s infinite both;
	}

	.circle-two {
		display: inline-block;
		background-color: $circle-color;
		margin: $circle-space;
		height: $circle-size;
		width: $circle-size;
		
		border-radius: 50%;
		animation: animation-size 0.8s linear 0.4s infinite both;
		animation-delay: -0.4s;
		-ms-animation-delay: -0.4;
		-webkit-animation-delay: -0.4s;
	}

	.circle-three {
		background-color: $circle-color;
		width: $circle-size;
		height: $circle-size;
		margin: $circle-space;

		display: inline-block;
		border-radius: 50%;
		animation: animation-size 0.8s linear 0s infinite both;
	}
	
	&.small {
		.circle-one, .circle-two, .circle-three {
			width: $circle-size-small;
			height: $circle-size-small;
			margin: $circle-space-small;
		}
	}

	&.home {
		position: relative;
		top: 75px;
		left: 50%;
		transform: translateX(-50%);
		width: 90px;
		height: 300px;
	}

	&.white {
		.circle-one, .circle-two, .circle-three {
			background-color: $white;
		}
	}
	
  .form-container & {
    z-index: 1;
    position: static;
    width: 100%;
    transform: translate(-50%, 0);
  }
}
  
@keyframes spin {
  to {
    transform: rotate(1turn); } }
  .loading-indicator .progress {
    position: relative;
    display: inline-block;
    width: 5em;
    height: 5em;
    margin: 0 .5em;
    font-size: 12px;
    text-indent: 999em;
    overflow: hidden;
    animation: spin 1s infinite steps(8); }
  .loading-indicator .small.progress {
    font-size: 6px; }
  .loading-indicator .large.progress {
    font-size: 12px;
    margin: 0 auto; }
  .loading-indicator .progress:before,
  .loading-indicator .progress:after,
  .loading-indicator .progress > div:before,
  .loading-indicator .progress > div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 2.25em;
    /* (container width - part width)/2  */
    width: .5em;
    height: 1.5em;
    border-radius: .2em;
    background: #eee;
    box-shadow: 0 3.5em #eee;
    /* container height - part height */
    transform-origin: 50% 2.5em;
    /* container height / 2 */ }
  .loading-indicator .progress:before {
    background: #555; }
  .loading-indicator .progress:after {
    transform: rotate(-45deg);
    background: #777; }
  .loading-indicator .progress > div:before {
    transform: rotate(-90deg);
    background: #999; }
  .loading-indicator .progress > div:after {
    transform: rotate(-135deg);
    background: #bbb; }

