section.account-detail {
        height: auto;

    .header_image { 
        background: url(/assets/images/headers/header_account_detail.jpg); 
        background-size: cover; 
        width: 100%; 
        position: relative;
        height: 200px;
        z-index: z($elements, hero);
    }
    .hero-section{
        height: 225px;

        .section-title-wrapper {
            position: absolute;
            // top: 0;
            bottom: 93px;
            left: 0;
            z-index: 3;
            padding-top: 0;

            h1{
                display: inline-block; 
                font-size: px2rem(24); 
                color: $white; 
                @include tiempos(medium); 
                text-align: left; 
                text-transform: capitalize;
                height: 34px;
            }
        }
    }
    .hero-section, &.page-content {
        // avoid getting overwritten by golfengin.scss
        @include breakpoint(medium){
            padding: 0;
        }
    }
    .account-detail-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: $white;
        width: 100%;
    }
    .account-detail-container {
        background-color: $white;
        width: 92%;
        margin-top: -33px;
        z-index: 3;
    }
    .select-menu-dropdown2tabs {
        border-bottom: 1px solid $gray_e;
        padding: 13px 15px 15px 15px;

        .title {
            display: block;
            text-transform: capitalize;
            font-size: px2rem(18);
        }
        .select-menu-dropdown2tabs-dropdown {
            .option{
                display: block;
                border-bottom: 1px solid $gray_e;
                border-top: none;
                color: $dark_green;
                margin: 0;
                padding: 15px 42px;
                text-align: left;
                background-color: unset;
                font-size: px2rem(18);               
                @include bentonsans(book);
                border-right: none;
                border-left: none;
    
                &.selected{
                    color: $lt_green;
                    
                    &:before {
                        content: '';
                        width: 6px;
                        height: 100%;
                        background-color: $lt_green;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                    }
                }
                &:hover {
                    color: $lt_green;
                }
                &.disabled {
                    color: $disabled_color;
    
                    &:hover {
                        cursor: default;
                    }
                }
            }
        }
    }  
    .account-avatars-view {
        width: 100%;
        margin-top: 24px;
        border-bottom: 1px solid $gray_e;

        .avatar-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 25px;
            border-bottom: 1px solid $gray_e;

            .greeting {
                h2 {
                    @include tiempos(medium);
                    font-size: px2rem(18);
                    color: $dark_green;
                }
                .instructions {
                    font-size: px2rem(11);
                    
                    &.closed {
                        @include bentonsans(medium);
                        color: $lt_green;
                        cursor: pointer;
                    }
                    &.open {
                        @include bentonsans(book);
                        color: $gray_6;
                    }
                }
            }
            .avatar, .avatar-image {
                width: 64px;
                height: 64px;
            }
        }
        .avatar-gallery {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            padding-top: 24px;
            padding-bottom: 36px;

            .avatar-gallery-item {
                width: calc(100% / 3);
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                padding-top: 5px;
                cursor: pointer;

                .gallery-item-image {
                    width: 64px !important;
                    height: 64px !important;
                    border-radius: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                }
                .gallery-item-info {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    margin-top: 9px;

                    .gallery-item-number {
                        @include bentonsans(book);
                        font-size: px2rem(10);
                        color: $gray_6;
                    }
                    .gallery-item-flower {
                        @include tiempos(medium);
                        font-size: px2rem(14);
                        color: $dark_green;
                    }
                }
                &.selected {
                    .gallery-item-image {
                        &:before {
                            content: '';
                            border: 3px solid $lt_green;
                            border-radius: 50%;
                            width: calc(100% + 4px) !important;
                            height: calc(100% + 4px) !important;
                            position: absolute;
                            top: -5px;
                            left: -5px;
                        }
                    }
                    .gallery-item-info {
                        .gallery-item-number {
                            color: $lt_green;
                        }
                        .gallery-item-flower {
                            color: $lt_green;
                        }
                    }
                }
            }
            .action-buttons.avatar-gallery-buttons {
                margin-top: 30px;
            }
        }
    }
    .account-profile-view {
        display: flex;
        flex-direction: column;
        padding-bottom: 36px;

        .personal-info {
            width: 100%;
            margin-top: 24px;
        }
        .security {
            width: 100%;
            margin-top: 36px;
            position: relative;

            .fadeOverlay { // used on Login and Sign Up pages
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 110%;
                z-index: 10;
                background-color: $gray_f;
                opacity: 0.4;
                display: none;
                transform: translate(-5%);
        
                &.show {
                    display: block;
                }
            }
        }
        .section-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: $dark_green;
                @include tiempos();
                font-size: px2rem(18);
                padding-top: 0;
            }
            .action {
                color: $lt_green;
                @include bentonsans(medium);
                font-size: px2rem(12);
                cursor: pointer;

                &.disabled {
                    color: $disabled_color;

                    &:hover {
                        cursor: default;
                    }
                }
            }
        }
        .section-content {
            width: 100%;
            margin-top: 18px;

            .profile-row {
                width: 100%;
                display: flex;
                align-items: baseline;
                margin-bottom: 9px;

                .profile-label {
                    width: 40%;
                    @include bentonsans(book);
                    font-size: px2rem(12);
                    color: $gray_6;
                }
                .profile-value {
                    width: 60%;
                    @include tiempos;
                    font-size: px2rem(14);
                    color: $dark_green;
                    word-wrap: break-word;
                    overflow-wrap: break-word;

                    input {
                        color: $dark_green;
                        border: none;
                        background-color: $white;
                    }
                }
            }
        }
        .form-content {
            padding: 0;

            .prefix-field {
                width: 47%;
            }
        }
    }
    .account-notifications-view {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 24px;

        .notifications-header {
            display: flex;
            justify-content: space-between;

            h3 {
                color: $dark_green;
                @include tiempos();
                font-size: px2rem(18);
            }
        }
        .notifications-content {
            margin-top: 9px;
            width: 100%;
            padding: 0;
            padding-bottom: 36px;
            
            .notification-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                padding-top: 9px;
                padding-bottom: 25px;
                border-bottom: 1px solid $gray_e;
                width: 100%;
                margin-top: 15px;

                .text {
                    width: 75%;
                }
                .notification-title {
                    @include bentonsans(book);
                    font-size: px2rem(14);
                    color: $dark_green;
                }
                .notification-description {
                    @include bentonsans(book);
                    font-size: px2rem(12);
                    color: $gray_6;
                    margin-top: 6px;
                }
            }
        }
    }
    .action-buttons {
        width: 100%;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .button-primary {
            width: 100%;
        }
        .cancel-link {
            @include bentonsans(medium);
            font-size: px2rem(14);
            color: $lt_green;
            cursor: pointer;
            text-align: center;
            margin-top: 30px;
        }
    }
}

.birthdate-container, .password-container, .ssn4-container {
    position: relative;

    i {
        position: absolute;

        &.icon-pwd-show, &.icon-pwd-hide, &.icon-help {
            right: 0;
            cursor: pointer;
            color: $gray_6;
            text-align: center;
            bottom: 12px;
            font-size: px2rem(20);
        }
        &.icon-pwd-show, &.icon-pwd-hide {
            bottom: unset;
            top: 30px;
        }
    }
}

@include breakpoint(small) {
    section.account-detail{
        .hero-section {
            .section-title-wrapper {
                top: unset;
                bottom: 93px;

                h1 {
                    font-size: px2rem(42); 
                    height: 64px;
                }
            }
        }
        .select-menu-dropdown2tabs {
            border-bottom: 1px solid $gray_e;
            padding: 13px 15px 15px 15px;
    
            .select-menu-dropdown2tabs-dropdown{
                display: none;
                width: auto;
                border-bottom: unset;
                @include dd-box-shadow;
                position: absolute;

                &.show {
                    display: block;
                }
            }
        }
    }
}

@include breakpoint(medium) {
    section.account-detail{
        .account-detail-content {
            background-color: $off_white;
            min-height: 100%;
            padding-bottom: 120px;
        }
        .account-detail-container {
            width: 96%;
            min-height: 400px;
            height: auto;
            margin-top: -75px;
            display: flex;
            align-items: stretch;
        }
        .select-menu-dropdown2tabs {
            border-bottom: none;
            padding: 0;
            border-right: 1px solid $gray_e;
            width: 20%;
            display: flex;
            align-items: stretch;

            .select-menu-dropdown2tabs-dropdown {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                border-bottom: none;
                box-shadow: none;

                // .option{
                //     display: block;
                //     border-bottom: 1px solid $gray_e;
                //     border-top: none;
                //     color: $dark_green;
                //     margin: 0;
                //     padding: 15px 42px;
                //     text-align: left;
                //     background-color: unset;
                //     font-size: px2rem(18);
                //     @include bentonsans(book);
                //     border-right: none;
                //     border-left: none;
        
                //     &.selected{
                //         color: $lt_green;
                        
                //         &:before {
                //             content: '';
                //             width: 6px;
                //             height: 100%;
                //             background-color: $lt_green;
                //             position: absolute;
                //             left: 0px;
                //             top: 0px;
                //         }
                //     }
                //     &:hover {
                //         color: $lt_green;
                //     }
                //     &.disabled {
                //         color: $disabled_color;
        
                //         &:hover {
                //             cursor: default;
                //         }
                //     }
                // }
            }
        }
        .account-detail-view {
            width: 80%;
            padding: 40px 84px 58px;
        }
        .account-avatars-view {
            margin-top: 0;

            .avatar-header {
                .greeting {
                    h2 {
                        font-size: px2rem(36);
                    }
                    .instructions {
                        margin-top: 8px;
                        font-size: px2rem(14);
                    }
                }
                .avatar, .avatar-image {
                    width: 94px;
                    height: 94px;
                }
            }
            .avatar-gallery {
                padding-bottom: 48px;

                .avatar-gallery-item {
                    width: calc(100% / 3);
                    flex-direction: row;
                    align-content: center;
                    padding: 5px;
                    margin-bottom: 6px;

                    .gallery-item-image {
                        margin-left: unset;
                        margin-right: unset;
                    }
                    .gallery-item-info {
                        margin-left: 15px;
                        margin-top: 0;
                        text-align: left;
                        width: calc(100% - 79px);

                        .gallery-item-number {
                            font-size: px2rem(12);
                        }
                        .gallery-item-flower {
                            font-size: px2rem(18);
                        }
                    }
                }
                .action-buttons.avatar-gallery-buttons {
                    margin-top: 36px;
                }                
            }
        }
        .account-profile-view {
            padding-bottom: 0;

            .personal-info {
                width: 100%;
                margin-top: 48px;
            }
            .security {
                width: 100%;
                margin-top: 36px;
            }
            .section-header {
               h3 {
                    font-size: px2rem(30);
                }
                .action {
                    font-size: px2rem(14);
                }
            }
            .section-content {
                margin-top: 18px;

                .profile-row {
                    min-height: 31px;
                
                    .profile-label {
                        width: 35%;
                        font-size: px2rem(14);
                    }
                    .profile-value {
                        width: 65%;
                        font-size: px2rem(24);

                        input {
                            font-size: px2rem(24);
                        }
                    }
                }
            }
            .form-content {
                width: 75%;
            }
        }
        .account-notifications-view {
            margin-top: 48px;

            .notifications-header {
                h3 {
                    font-size: px2rem(30);
                }
            }
            .notifications-content {
                margin-top: 42px;
                padding-bottom: 0;

                .notification-item {
                    margin-top: 0;

                    .notification-title {
                        font-size: px2rem(16);
                    }
                }
            }
        }
        .action-buttons {
            margin-top: 30px;
            flex-direction: row;
            align-items: center;
            
            .button-primary {
                width: 230px;
                margin-top: 0;
            }
            .cancel-link {
                margin-left: 24px;
                margin-top: 0;
                text-align: left;
            }
        }
    }
    .birthdate-container, .password-container {
        .float-label, .fixed-label {
            i {
                &.icon-pwd-show, &.icon-pwd-hide, &.icon-help_icon {
                    font-size: px2rem(24);
                }
            }
        }
    }
}

@include breakpoint (large) {
    section.account-detail{
        .header_image { height: 375px; }
        .hero-section {
            height: 375px;

            .section-title-wrapper {
                top: calc(40% - 32px);

                h1 {
                    font-size: px2rem(46); 
                    height: 64px;
                }
            }
        }
        .select-menu-dropdown2tabs {
            width: 25%;
        }
        .account-detail-view {
            width: 75%;
            padding: 60px 114px 78px;
        }
        .account-notifications-view {
            .notifications-content {
                .notification-item {
                    .notification-title {
                        font-size: px2rem(18);
                    }
                    .notification-description {
                        margin-top: 3px;
                        font-size: px2rem(14);
                    }
                }
            }
        }
    }
}

.webview {
    section.account-detail {
        width: 100%;

        .account-detail-view {
            width: 92%;
            margin: 0 auto;

            h3 {
                padding-left: 0;
            }
        }
    }
}

.avatar {
    background-size: cover;
    background-repeat: no-repeat;

    &.hole1 {
        background-image: url(/assets/images/login/avatars/hole1.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole1_sm.svg);
        }
    }
    &.hole2 {
        background-image: url(/assets/images/login/avatars/hole2.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole2_sm.svg);
        }
    }
    &.hole3 {
        background-image: url(/assets/images/login/avatars/hole3.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole3_sm.svg);
        }
    }
    &.hole4 {
        background-image: url(/assets/images/login/avatars/hole4.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole4_sm.svg);
        }
    }
    &.hole5 {
        background-image: url(/assets/images/login/avatars/hole5.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole5_sm.svg);
        }
    }
    &.hole6 {
        background-image: url(/assets/images/login/avatars/hole6.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole6_sm.svg);
        }
    }
    &.hole7 {
        background-image: url(/assets/images/login/avatars/hole7.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole7_sm.svg);
        }
    }
    &.hole8 {
        background-image: url(/assets/images/login/avatars/hole8.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole8_sm.svg);
        }
    }
    &.hole9 {
        background-image: url(/assets/images/login/avatars/hole9.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole9_sm.svg);
        }
    }
    &.hole10 {
        background-image: url(/assets/images/login/avatars/hole10.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole10_sm.svg);
        }
    }
    &.hole11 {
        background-image: url(/assets/images/login/avatars/hole11.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole11_sm.svg);
        }
    }
    &.hole12 {
        background-image: url(/assets/images/login/avatars/hole12.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole12_sm.svg);
        }
    }
    &.hole13 {
        background-image: url(/assets/images/login/avatars/hole13.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole13_sm.svg);
        }
    }
    &.hole14 {
        background-image: url(/assets/images/login/avatars/hole14.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole14_sm.svg);
        }
    }
    &.hole15 {
        background-image: url(/assets/images/login/avatars/hole15.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole15_sm.svg);
        }
    }
    &.hole16 {
        background-image: url(/assets/images/login/avatars/hole16.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole16_sm.svg);
        }
    }
    &.hole17 {
        background-image: url(/assets/images/login/avatars/hole17.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole17_sm.svg);
        }
    }
    &.hole18 {
        background-image: url(/assets/images/login/avatars/hole18.svg);

        &.small {
            background-image: url(/assets/images/login/avatars/hole18_sm.svg);
        }
    }
}