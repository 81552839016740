#payment-container {
    width:100%;
    height:500px;
}

#payment-page {
    .total {
        text-align: right;
        @include bentonsans(medium);
        font-size:px2rem(18);
        margin-top:20px;
        margin-right:30px;
    }

    .todo {
        font-style:italic;
        margin:20px 0px;
    }

    .countdown-wrapper {
        text-align: center;
        font-weight: bold;
        //.countdown-timer {color:$dark_green}
    }
}

.card-header{
    font-family: "Tiempos",Times New Roman, serif;
    font-weight: 500;
    font-family: "Tiempos","Tiempos Text",Times New Roman, serif;
    font-weight: 500;
    color: #006747;
    margin-bottom: 30px;
    margin-top:50px;
    font-size: 1.25rem;
    font-weight: 500;
}
.card-icons{
margin-bottom:30px;
margin-right:10px;
height:20px;
}

.tooltip .tooltiptext {
    background-color: #FFFFFF;
    width: 150px;
    color: #6B6F73;
    text-align: center;
    border: 1px solid #cccccc;
    box-shadow: 0px 2px 4px 0px, 0px 1px 3px 0px inset;
    padding: 10px 10px;
    font-weight: 500;
    font-size: 0.75rem;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  .tooltip{
   position: absolute;
   left: 38%;
   top: 78%;
   transition: visibility 0s 0s, opacity 2s linear;
  }
  @include breakpoint(small,max){

    .tooltip{
        left: 45%;
        top: 85%;
    }
  }
 
