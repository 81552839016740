.message-content {
    line-height:24px;

    p {
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    .bold-text {
        @include bentonsans(medium)
    }
    .red-text {
        color: $bright_red;
    }
}
[data-cms="account.submit_button"], [data-cms="account.submit_next_button"] {
    text-transform: uppercase;
    &::before,&::after {content:"\""}
}
