footer {
	&#bottom {
		position: relative;
		background-color: $dark_green;
		border-top: solid 4px $yellow;
		padding: 40px 30px;
		min-height: 176px;
		color: $white;
		@include bentonsans;
		font-size: 14px;

		.container {
			max-width: $max-content-width;
			margin-left: auto;
			margin-right: auto;

			.right {
				text-align: left;
				margin-top: 40px;

				@media (min-width: 768px) {
					text-align: right;
					margin-top: 0;
				}
			}

			.row {
				display: block;

				@media (min-width: 768px) {
					display: flex;
				}
			}
		}

		.col {
			flex-basis: 0;
    		flex-grow: 1;
		}

		p, a {
			color: $white;
		}

		a:hover {
			color: $white;
			text-decoration: underline;
		}

		.list-inline {
			padding-left: 0;
    		list-style: none;
			position:relative;
		}

		.list-inline-item {
			display: inline-block;
			margin-bottom: 15px;
			
			&.facebook,
			&.twitter,
			&.instagram
			{
				font-size: 30px;
				a {
				text-decoration: none;
				}
			}
		}

		.list-inline-item:not(:last-child) {
			margin-right: 3rem;

			&.copyright {
				display: flex;
				flex-direction: row;
				align-items: baseline;
				margin-right:0px;
			}
		}

		.partnership {
			img {
				vertical-align: baseline;
				margin-left: 5px;
			}
		}
		
		.facebook img {
			width: 30px;
			height: 30px;
		}

		.instagram img {
			width: 30px;
			height: 30px;
		}

		.twitter img {
			width: 32px;
			height: 26px;
		}
		.tiktok img {
			width: 26px;
			height: auto;
		}
		.youtube img {
			width: 36px;
			height: auto;
		}

		i.icon-augusta_logo {margin: 0px 20px;}

	}

}
