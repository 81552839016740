// show dropdown on all the breakpoint - font sizes vary based on breakpoints

.select-menu-primary{
    width: 100%;
    position: relative;

    button {
        background: transparent;
    }

    .title{
        background: none;
        display: inline-block;
        position: relative;
        width: auto;
        height: 30px;
        border: 0px;
        color: $dark-green;
        text-decoration: none;
        text-align: left;
        padding: 0 30px 0 0;
        opacity: 1;
        cursor: pointer;
        @include tiempos;
        font-size: #{px2rem(18px)};

        &.border {
            border: 1px solid $dark-green;
		}

		.menu-arrow {
			font-size: 18px;
			line-height: 10px;
        }

        @include breakpoint(small) {
            font-size: #{px2rem(24px)};
        }

        @include breakpoint(medium) {
            font-size: #{px2rem(30px)};
            height: 40px;
        }
    }

    &-dropdown{
        background: $white;
        position: absolute;
        z-index: 100;
        display: none;
        min-width: 175px;
        padding: 0;
        @include bentonsans(semibold);
        font-size: #{px2rem(15px)};
        max-height: 400px;
        @include dd-box-shadow;

        &.show {
            display: block;
        }

        a:hover {
            text-decoration: none;
            color: $lt_green;
        }

        .option{
            display: block;
            border-top: 1px solid $gray_e;
            color: $dark-green;
            margin: 0;
            padding: 10px 15px;;
			text-align: left;
            background-color: unset;
            position: relative;

            &.selected{
                color: $lt_green;

                &:before {
                    content: '';
                    width: 3px;
                    height: 100%;
                    background-color: $lt_green;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                }

            }

            @include breakpoint(small) {
                font-size: #{px2rem(14px)};
            }

            @include breakpoint(medium) {
                font-size: #{px2rem(16px)};
            }
        } // option

        button {
            flex: 0 0 auto;
            position: relative;
            white-space: nowrap;
            @include bentonsans(semibold);
            font-size: #{px2rem(15px)};

            text-decoration: none;
            border: 0;
            cursor: pointer;
            width: 100%;

            &:first-child {
                border-top: none;
            }
        }

        .section {
            text-align: center;
            border-bottom: 1px solid $dark-green;
        }
    }

    .navigation_down_arrow { position: relative;
        &:after { position: absolute; top: 15px; right: 5px; @include border-arrow(10px, 5px, $dark_green, bottom);

            @include breakpoint(small) {
                top: 15px;
            }
            @include breakpoint(medium) {
                top: 20px;
            }
        }
        &.gray_8 {
          &:after { @include border-arrow(8px, 4px, $gray_6, bottom); }
        }

        .active &, &.active, .open &, &.open {
          &:after { transform: rotate(180deg); transition: 0.15s transform ease-in-out; }
        }
    }

}
