@font-face {
    font-family: 'Icons';
    src:  
      url('/assets/fonts/icons/masters-icons.ttf?io6dyw') format('truetype'),
      url('/assets/fonts/icons/masters-icons.woff?io6dyw') format('woff'),
      url('/assets/fonts/icons/masters-icons.svg?io6dyw#masters-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @mixin masters-icon($ipkg) {
    font-family       : $ipkg !important;
    font-smoothing    : antialiased;
    font-style        : normal;
    font-weight       : normal;
    font-variant      : normal;
    text-transform    : none;
    speak             : none;
    line-height       : 1;
    font-display      : swap;
}

// Icons List
$icons: (
  "icon-office365": "\e937",
  "icon-outlook": "\e938",
  "icon-yahoo": "\e985",
  "icon-x-base":"\e972",
  "icon-x": "\e916",
  "icon-google": "\e931",
  "icon-apple": "\e958",
  "icon-ics": "\e958",
  "icon-star-outline": "\e912",
  "icon-star": "\e913",
  "icon-video-false": "\e919",
  "icon-video-true": "\e91a",
  "icon-watch": "\e91c",
  "icon-track-play": "\e920",
  "icon-lock-false": "\e925",
  "icon-lock-path1": "\e930",
  "icon-lock-path2": "\e937",
  "icon-lock-path3": "\e938",
  "icon-menu-false": "\e93b",
  "icon-menu-true": "\e93c",
  "icon-info": "\e93d",
  "icon-info-true": "\e941",
  "icon-leaderboard": "\e944",
  "icon-leaderboard-true": "\e947",
  "icon-help": "\e949",
  "icon-help-true": "\e94a",
  "icon-home-false": "\e94c",
  "icon-home-true": "\e94f",
  "icon-track-outline": "\e950",
  "icon-track-no-circle": "\e959",
  "icon-error-false": "\e95a",
  "icon-error": "\e95c",
  "icon-arrow-down": "\e95d",
  "icon-arrow-up": "\e95e",
  "icon-table-view": "\e95f",
  "icon-teetimes": "\e960",
  "icon-pwd-hide": "\e961",
  "icon-pwd-show": "\e962",
  "icon-radio-wave": "\e963",
  "icon-account": "\e964",
  "icon-print": "\e965",
  "icon-podcast": "\e966",
  "icon-player-profile": "\e967",
  "icon-notification": "\e968",
  "icon-my-stream": "\e969",
  "icon-news": "\e96a",
  "icon-infographic": "\e96b",
  "icon-chevron-left": "\e96c",
  "icon-chevron-right": "\e96d",
  "icon-chevron-up": "\e96e",
  "icon-chevron-down": "\e96f",
  "icon-face-id": "\e970",
  "icon-touch-id": "\e971",
  "icon-augusta_logo": "\e95b",
  "icon-radio-checked": "\e956",
  "icon-radio-unchecked": "\e957",
  "icon-commentator": "\e955",
  "icon-ibm-logo": "\e953",
  "icon-masters-logo": "\e954",
  "icon-link-arrow": "\e94e",
  "icon-email": "\e951",
  "icon-facebook-circle": "\e952",
  "icon-triangle-down-small": "\e94d",
  "icon-valid": "\e94b",
  "icon-ecp-captions-off": "\e900",
  "icon-ecp-captions-on": "\e901",
  "icon-ecp-fullscreen-off": "\e902",
  "icon-ecp-fullscreen-on": "\e903",
  "icon-ecp-live-off": "\e904",
  "icon-ecp-live-on": "\e905",
  "icon-ecp-mute-off": "\e906",
  "icon-ecp-mute-on": "\e907",
  "icon-ecp-mute": "\e908",
  "icon-ecp-panel-off": "\e909",
  "icon-ecp-panel-on": "\e90a",
  "icon-ecp-pause": "\e90b",
  "icon-ecp-play": "\e90c",
  "icon-ecp-popopen": "\e90d",
  "icon-ecp-settings": "\e90e",
  "icon-ecp-share": "\e90f",
  "icon-ecp-skipback": "\e910",
  "icon-add": "\e936",
  "icon-settings": "\e935",
  "icon-facebook": "\e932",
  "icon-instagram": "\e933",
  "icon-twitter": "\e934",
  "icon-mute": "\e92f",
  "icon-archive": "\e92e",
  "icon-close-circle": "\e92d",
  "icon-plus": "\e92c",
  "icon-cc": "\e926",
  "icon-fullscreen": "\e928",
  "icon-pip": "\e929",
  "icon-stop": "\e92a",
  "icon-vid-1": "\e923",
  "icon-vid-2": "\e924",
  "icon-vid-3": "\e927",
  "icon-vid-4": "\e92b",
  "icon-track-pause": "\e922",
  "icon-triangle": "\e921",
  "icon-add-player": "\e91b",
  "icon-track-anchor": "\e91d",
  "icon-zoom-in": "\e91e",
  "icon-zoom-out": "\e91f",
  "icon-360": "\e911",
  "icon-calendar": "\e914",
  "icon-camera": "\e915",
  "icon-download": "\e917",
  "icon-filter": "\e918",
  "icon-link": "\e939",
  "icon-more": "\e93a",
  "icon-radio": "\e93e",
  "icon-replay": "\e93f",
  "icon-share": "\e940",
  "icon-search": "\e942",
  "icon-close": "\e943",
  "icon-triangle-down": "\e945",
  "icon-triangle-up": "\e946",
  "icon-track": "\e948",
  "icon-info1": "\ea0c"
);

.icon,
i[class^=icon-] {
    @include masters-icon('Icons');
}

@each $p, $c in $icons {
    $i: index($icons, ($p $c));
    .#{$p}:before {
        content: $c;
    }
}

// for toolkit display
.toolkit-icon-fonts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .glyph {
        margin-bottom: 32px;
    }

    .mls {
        font-size: 11px;
        margin-left: 20px;
        vertical-align: middle;
    }

    > div {
        width: 50%;
        max-width: 50%;
    }

    i[class^=icon-] {
        font-size: 30px;
    }
}

// global default settings
$hover_lt_green: #107D57;

.icon-my-moments {
    font-size: 40px;
    &.inactive {
        color: #cccccc;
    }
    &.active {
        color: $hover_lt_green;
    }
}

.icon-track-play, .icon-track-pause{
    font-size: 33px;
    &.inactive {
        color: #cccccc;
    }
    &.active {
        color: $yellow;
    }
}

.icon-triangle {
    color: $yellow;
}

.icon-archive {
    font-size: 40px;
    &.inactive {
        color: #888B8D;
    }
    &.active {
        color: $hover_lt_green;
    }
}

.icon-track-no-circle {
    &:hover {
        color: $hover_lt_green;
    }
}