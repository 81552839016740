.page-content{
    &.page-hdr-title{ padding-bottom: 15px; padding-top: 15px; padding-left: 15px;
        @include breakpoint(medium){ padding-left: 30px; }
        .select-menu-primary{ display: inline-block; width: auto; 
            .title{text-transform: capitalize; font-size: px2rem(18); 
                &:before {
                    content: "Patron Info - \00a0";
                    display: inline-block;
                } 
                @include breakpoint(medium){ font-size: px2rem(30); }
            }
            .navigation_down_arrow:after{ top: 15px; 
                @include breakpoint(medium){ top: 20px; }
            }

        }
    }
    &.patronInfo{
        .select-menu-primary-dropdown { min-width: 100%; position: absolute; 
            // @include breakpoint(medium){ left: -190px;}
        }    
        .select-menu-dropdown2tabs{ 
            .title{ text-transform: capitalize;}
        }
    }
    
}
article {
    padding: 20px 0px; max-width: $max-content-width; margin: 0px auto;
    overflow: hidden; //amb - 102318 gallery styling
    
    .header{ color: $dark_green; margin-bottom: 15px; font-size: px2rem(30); @include tiempos;
        .count { font-size: px2rem(16); color: $lt_green; @include bentonsans(medium);}
        .icon-camera{ color: $lt_green; font-size: px2rem(18); margin: 0px 10px;}
    }
    .text{
        &.centered{
            @include clearfix; 
            & { 
                margin: auto auto ; 
                width: 92%;
            }
            @include breakpoint(medium){ max-width: $article-max-content; }
        }
        &.extra_spacing{
            // p{ padding-bottom: 1em; padding-top: 1em;}
            ul, ol { padding-left:4em; color: $gray_2; line-height: 1.8em; padding-bottom: 1.8em;
                ul, ol { padding-bottom: 1.8em; }
            }
        }
        &.gray_hr{ border-bottom: 1px solid rgba(0,0,0,0.1); margin-bottom: 50px; }
        &.multi-col{
            @include breakpoint(medium){
                width: 45%; margin-left: 2%; display: inline-block; vertical-align: top;
            }
        }

        &.half{ width: 75%; margin-left: 5%; display: inline-block;
            &.multi-col{  width: 100%; vertical-align: top;
                @include breakpoint(small){ width: 33%;}
                @include breakpoint(medium){ width: 35%; margin-left: 2%;}
            }
        }
        &.indent{ margin-left: 5%!important;}


        h2{ font-size: px2rem(22);color: $dark_green; @include tiempos; text-align: left;
            @include breakpoint(medium){ font-size: px2rem(36); }
        }
        h3{ font-size: px2rem(18); line-height: 1.09em; text-transform: capitalize; @include tiempos;color:$dark_green;
            @include breakpoint(medium){ font-size: px2rem(24);  text-transform: capitalize; }
        }
        h4{ font-size: px2rem(16); color: $dark_green;
            a{ text-decoration: none; color: $dark_green;}
        }
        h5{ font-size: px2rem(12); padding-bottom:10px; @include bentonsans(medium); color: $lt_green; 
            a{ text-decoration: none; color: $lt_green;}
            &.chevron { position: relative;}
        } 
        p{ font-size: px2rem(16); line-height: px2rem(26); padding-bottom: 25px;
            &.attention{ color: $bright_red;}
        }
        ul, ol{ padding-left: 1.8em; padding-bottom: 25px; }  
        ul, ol{
            &.checkmarks{ list-style: none; list-style-type: none!important; padding-left: 5px;
                li:before{ content: '\2713';color: $dark_green; padding-right: 10px; }
            }
            &.xmarks{ list-style: none; list-style-type: none!important; padding-left: 5px;
                li:before{ content: '\2715';color: $dark_red;padding-right: 10px; }
            }
        } 

        .print_link{ 
            @include bentonsans(medium); font-size: px2rem(16); text-decoration: none; color: $lt_green; cursor: pointer; 
        }
    }
    .articleInfo, .tournShare{ padding-bottom: 30px; padding-top: 15px;
        .author_date{ color: $gray_6; font-size: px2rem(12); margin-top: 5px; width: 95%; display: inline-block;
            @include breakpoint(large){ font-size: px2rem(14); }
            .date{ display: inline-block;}
          }
        
        .actions{ 
            @include bentonsans('bold'); 
            & { text-transform: uppercase; 
                color: $gray_6; 
                position: relative; 
            }
            @include clearfix; 
            & {
            float: right; 
            top: -30px;
            }
            .share{display: inline; text-align:left; color: $dark_green; text-indent: -9999px; font-size: px2rem(28);}
        }
        
        .title{ margin: 0 0 0.25em 0; @include tiempos; font-size: px2rem(22); color:$dark_green; margin-left: 0px; width: 95%;
            @include breakpoint(medium){ font-size: px2rem(30); }
        }
    }
    .image-wrapper{background-color: $off_white; max-height: 810px; 
        img{ width: 100%; text-align: center; height: auto; display: block;
            @include breakpoint(medium){ width: auto; text-align: center; height: 60vh; max-height: 810px; margin: 0px auto; display: block;}
        }
    }
    &.health_and_safety{
        .half{
            p{ padding-bottom: 0.3em; }
        }
    }   
}
.page-content{
    .tournShare{ display: none; /*remove share from tourninfo - 012119-amb*/
        // @include clearfix; margin: auto auto ; width: 92%; padding:0px;
        // @include breakpoint(medium){ max-width: $article-max-content; position: absolute; left: 10%;}
        // @include breakpoint(xlarge){ position: relative;}
        
        // .actions{ top: 0px;
        //     @include breakpoint(medium){ top: -10px; right: 10%;}
        //     @include breakpoint(large){ top: 30px; right: 0px;}
        // }
       
    }
}

.accept-content {
    display:none;
}
.accept-page {
    .accept-content {
        display:block;
    }
}

.policy-buttons{
    text-align:center;
    margin-bottom: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}