.toggle-wrapper {
    display:flex;
    flex-direction:row;
    align-items: center;
    .toggle-label {
        color:#6B6F73;
        @include bentonsans(medium);
        font-size: px2rem(12);
        margin-right:12px;
    }
    .toggle-button {
        width: 55px;
        height:26px;
        border:solid 1px #d9d9d6;;
        border-radius:13px;
        position: relative;
        display:flex;
        align-items: center;
        font-size: px2rem(10);
        @include bentonsans(medium);
        text-transform: uppercase;
        cursor: pointer;
        &:before {
            content:"";
            width:20px;
            height:20px;
            border-radius: 20px;
            position: absolute;
            top:2px;
        }
        &.on {
            color:$lt_green;
            padding-left:6px;
            &:before {
                background:$lt_green;
                right:3px;
            }
        }
        &.off {
            color:#6B6F73;
            padding-right:6px;
            flex-direction: row-reverse;
            &:before {
                background:#6B6F73;
                left:3px;
            }
        }
    }
}