
// show tabs nav style

.select-menu-tabs {
    width: 100%;
    position: relative;

    button {
        background: transparent;
        a {
            text-decoration: none;
        }
    }

    .title{
        display: none;
    }

    &-dropdown{
        display: block;
        box-shadow: none;
        position: relative;
        width: 100%;
        border-bottom: 2px solid $lt_green;
        font-size: #{px2rem(11px)};

        .option {
            @include bentonsans(medium);
            border-top: 1px solid $gray_d;
						border-right: 1px solid $gray_d;
						border-bottom: none;
						border-left: 1px solid transparent;
						background: $white;
            color: $dark_green;
            display: inline-block;
            font-size: #{px2rem(13px)};
            line-height: #{px2rem(45px)};
            padding: 0 25px;
            margin: 0;
            width: auto;

            &:first-child {
								border-left: 1px solid $gray_d;
            }

            &.selected {
								background: $lt_green;
                color: $white;
								border-top: 1px solid $lt_green;
								border-left: 1px solid $lt_green;
								border-right: 1px solid $lt_green;

                &:before {
                    background: none;
                }

                &:hover {
                    cursor: default;
                }
                a {
                    color:$white;
                }
            }

            &:hover {
                background: $lt_green;
                color: $white;
                border-top: 1px solid $lt_green;
                //border-left: 1px solid $lt_green;
                border-right: 1px solid $lt_green;
                cursor: pointer;
                a {
                    color: $white;
                }
            }

            &.disabled {
                color: $disabled_color;

                &:hover {
										border-top: 1px solid $gray_d;
										border-right: 1px solid $gray_d;
										border-left: 1px solid transparent;
										background: $white;
                    color: $disabled_color;
                    cursor: default;
                }
            }

            @include breakpoint(medium) {
                font-size: #{px2rem(14px)};
            }
        }
    } // -dropdown

    > div.select-menu-tabs-dropdown {
        text-align: left;
        .option {
            text-align: center;
        }
        a {display:block; text-decoration: none; width:100%; text-align: center;}
    }
    .navigation_down_arrow { position: relative;
        &:after { position: absolute; top: 15px; right: -10px; @include border-arrow(10px, 5px, $dark_green, bottom); }
        &.gray_8 {
          &:after { @include border-arrow(8px, 4px, $gray_6, bottom); }
        }

        .active &, &.active, .open &, &.open {
          &:after { transform: rotate(180deg); transition: 0.15s transform ease-in-out; }
        }
    }

}
