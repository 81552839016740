.log-in-view {
    .form-content {
        margin-top: 0;
        
        .log-in-container {
            width: 100%;
        }
        .create-account-container {
            width: 100%;
        }
        .form-divider {
            display: block;
            width: 1px;
            height: auto;
            background-color: #B1B3B3;
        }
        .forgot-password-link {
            margin-top: 15px;
        }
        .create-account-link {
            margin-top: 15px;
            text-align: center;
        }
        .masters-home-link {
            text-align: center;
        }
        .magic-login-link {
          width: 100%;
        }
    }

    @include breakpoint(small) {
        padding-top: 0;

        .form-content {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .log-in-container {
                width: 46%;
            }
            .create-account-container {
                width: 46%;
            }            
            .forgot-password-link {
                font-size: px2rem(14);
            }
            .masters-home-link, .divider-line, .create-account-link {
                display: none;
            }
        }
    }

    &.patron {
        .form-content {
            justify-content: center;
        }
    }
}