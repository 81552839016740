.review-submit{

    @include tiempos(medium);
    .hero-section{
        padding:0px;
    }
    .text{
        .section_title{
            color: #0D6747;
            font-weight:normal;
        }
    }
    
    .ticket-info{
    // width:50%;
        font-weight: normal;
        margin-top:30px;

        .header{
            @include tiempos(medium);
            color: #006747;
            margin-bottom:-50px;
            font-size:px2rem(20);
            font-weight: 500;
            margin-bottom: 15px;
            div:nth-child(1){
                width:30%;
                float:left;
                
            }
            .edit-account-info{
                @include bentonsans(medium);
                
                p{
                    font-size:px2rem(12);
                    color:#009B77;
                    
                }
            }
        }
    
    
    }
    .policy-buttons{
        margin-top:10%;
        display:flex;
        justify-content:space-evenly;
        a{
        width: 30%;
        padding: 30px;
        font-size: px2rem(14);
        font-weight: normal;
        text-decoration: none;
        }
    }
}