.calendarButton-wrapper {
	// display: flex;
	// justify-content: center;
	margin-bottom: 30px;

	// --buttonColor is used for the plugin.
	--buttonColor: #006747;
	.dcp-site & {
		--buttonColor: #002d73;
	}
	.anwa-site & {
		--buttonColor: #00ac69;
	}
}


.calendarButton-wrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
    flex-direction: column;
    text-align: center;
    position: relative;

	.calendarButton {
		width: auto;
		margin: 0px auto;
	}

	.calendarButton-options.select-menu-primary-dropdown {
		left: 0px;
		right: 0px;
		top: 42px;
		width: 275px;
		margin: 0px auto;

		.option {
			&:hover {
				color: var(--buttonColor);// #107d57;
				&:before {
					content: "";
					width: 3px;
					height: 100%;
					background-color: var(--buttonColor); //#107d57;
					position: absolute;
					left: 0px;
					top: 0px;
				}
			}
		}

		&.show {
			a {display:flex; flex-direction: row; justify-content: flex-start; text-decoration: none;}
			i {color: $black; margin-right:10px;}
		}
		
	}
}

