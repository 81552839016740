.system-error-wrapper {
    margin-bottom: 40px;
    h3 {
        i {font-size: px2rem(8); margin-left:20px;}
        & {cursor: pointer;}
        & {display:flex;}
        & {flex-direction: row;}
        & {align-items: center;}
    }
    .error-section {
        max-height: 0;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
        display:flex;
        flex-direction: column;

        &.open {
            max-height: 3000px;
            transition: max-height 0.25s ease-in;
        }

        .label {
            font-weight: bold;
            margin-bottom:20px;
        }

        .value {
            font-weight: normal;
            margin-bottom: 20px;

        }
    }
}